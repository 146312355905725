import {Component, OnInit, ElementRef, ViewChild, Input} from '@angular/core';
import { ManageService } from '../../../core/manage.service';
import { LoadingService } from '../../../shared/full-loading/loading.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-level-title',
  templateUrl: './level-title.component.html',
  styleUrls: ['./level-title.component.scss']
})
export class LevelTitleComponent implements OnInit {
  @ViewChild('name') nameField: ElementRef;
  levelName: string;
  subjectName: string;
  levelId: number;
  subjectId: number;
  currentRelId: number;
  firstTitle: string;
  inputShow = false;
  parent: Array<OutlinesResult> = [];
  child: Array<OutlinesResult> = [];
  @Input() readonly: boolean;
  constructor(
    private manageService: ManageService,
    private load: LoadingService,
    ) {}

  ngOnInit() {
    this.load.show();
    // 获取到当前选中的学段，学科等信息
    this.manageService.getCurrentSelected().subscribe((res) => {
      this.levelName = res[0].levelName;
      this.levelId = res[0].levelId;
      this.subjectName = res[1].subjectName;
      this.subjectId = res[1].id;
      if (_.size(res[0]) !== 0 && _.size(res[1]) !== 0 ) {
        this.manageService.getOutlines(this.levelId, this.subjectId).subscribe(result => {
          _.forEach(result, item => {
            item.isEditing = false;
            item.isButtonShow = false;
            item.isInputShow = false;
          });
          this.parent = this.getTree({}, -1, result);
        })
      } else {
        this.parent = [];
      }
    });
    this.load.hide();
  }
  // 将数据生成树状结构
  public getTree(root: any, id: number, nodes: OutlinesResult[]) {
    let child = _.filter(nodes, node =>  node.parentId === id);
    child = _.sortBy(child, 'sort');
    _.each(child, c =>  this.getTree(c, c.id, nodes));
    root.child = child;
    this.load.hide();
    return root.child;
  }

  public addFistTitle($event: Event) {
    $event.stopPropagation();
    this.restStatus(this.parent);
    this.firstTitle = '';
    this.inputShow = true;
    this.inputClick($event);
    setTimeout(() => {
      this.editName();
    })
  }

  public hiddenInput() {
    this.inputShow = false;
  }

  public inputClick($event: Event) {
    $event.stopPropagation();
  }

  public creat($event: Event) {
    $event.stopPropagation();
    this.inputShow = false;
    this.load.show();
    const data = {
      name: this.firstTitle,
      depth: 1,
      parentId: -1,
      sort: 1,
      levelId: this.levelId,
      subjectId: this.subjectId
    };
    if (this.firstTitle) {
      this.manageService.addNode(data).subscribe(i => {
        this.parent.push(i);
        this.load.hide();
      }, (err) => {
        this.load.hide();
      })
    } else {
      this.load.hide();
    }
  }
  // 重置所有节点编辑，按钮是否展示状态为false
  public restStatus(arry: OutlinesResult[]) {
    _.forEach(arry, item => {
      item.isEditing = false;
      item.isInputShow = false;
      item.isButtonShow = false;
      if (item.child) {
        return this.restStatus(item.child);
      }
    })
  }

  // input的获取焦点事件
  public editName(): void {
    this.nameField.nativeElement.focus();
  }

}
