import {Component, Input, OnInit} from '@angular/core';
import { NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageService } from '../../../core/manage.service';
import * as _ from 'lodash';
import {toastErrorLoad} from '../../../core/utils';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-add-or-edit-school',
  templateUrl: './add-or-edit-school.component.html',
  styleUrls: ['./add-or-edit-school.component.scss']
})
export class AddOrEditSchoolComponent implements OnInit {
  @Input() modalTitle;
  @Input() editSchool;
  @Input() editSubjects;
  provinces = [];
  allCities = [];
  cities = [];
  allZones = [];
  zones = [];
  allSchools = [];
  schools = [];
  selectProvince;
  selectCity;
  selectZone;
  selectSchool;
  subjects = [];
  selectSubjects = [];
  showValidationError = false;
  constructor(
    private ngbModal: NgbModal,
    public activeModal: NgbActiveModal,
    private managerService: ManageService,
    private toast: ToastrService
  ) { }

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    this.managerService.getAreaAndSchools().subscribe((res: any) => {
      this.provinces = res.provinces;
      this.allCities = res.citys;
      this.allZones = res.zones;
      this.allSchools = res.schools;
      if (this.editSchool) {
        this.selectProvince = _.find(this.provinces, p => p.id.toString() === this.editSchool.province);
        this.selectZone = _.find(this.allZones, z => z.id.toString() === this.editSchool.zone);
        this.selectCity = _.find(this.allCities, c => c.id === this.selectZone.parentId);
        this.selectSchool = _.find(this.allSchools, s => s.schoolId === this.editSchool.id);
        const subs = _.map(this.editSubjects, 'id');
        this.subjects = [];
        this.managerService.getSubjects(0).subscribe(sub => {
          _.forEach(sub, r => {
            if (_.includes(subs, r.id)) {
              r.checked = true;
            } else {
              r.checked = false;
            }
            this.subjects.push(r);
          });
        })
      } else {
        this.changeProvince(this.provinces[0]);
        this.getSubjects();
      }
    })
  }
  changeProvince(province) {
    this.selectProvince = province;
    this.cities = _.filter(this.allCities, (c) => c.parentId === province.id);
    this.changeCity(this.cities[0]);
  }
  changeCity(city) {
    this.selectCity = city;
    this.zones = _.filter(this.allZones, (z) => z.parentId === city.id);
    this.changeZone(this.zones[0]);
  }
  changeZone(zone) {
    this.selectZone = zone;
    this.schools = _.filter(this.allSchools, (s) => s.zongId === zone.id);
    this.changeSchool(this.schools[0]);
  }
  changeSchool(s) {
    this.selectSchool = s;
  }
  getSubjects() {
    this.subjects = [];
    this.managerService.getSubjects(0).subscribe(res => {
      _.forEach(res, r => {
        r.checked = false;
        this.subjects.push(r);
      });
    })
  }
  selectSubject (sub) {
    sub.checked = !sub.checked;
    this.selectSubjects = _.filter(this.subjects, (s) => s.checked);
    if (this.selectSubjects.length > 0) {
      this.showValidationError = false;
    }
  }
  checkHasSubjectsChecked() {
    return _.some(this.subjects, s => s.checked)
  }
  close() {
    this.activeModal.close();
  }
  ok() {
    const subjectIds = _.map(this.selectSubjects, 'id');
    if (subjectIds.length > 0) {
      this.managerService.updateSchoolInfo(this.selectSchool.schoolId, subjectIds, this.modalTitle === '添加学校').subscribe(res => {
        this.activeModal.close(true);
      }, e => {
        console.log(e.status)
        if (e.status === 400) {
         toastErrorLoad(this.toast, '已添加过该学校，请勿重复添加');
        }
      })
    } else {
      this.showValidationError = true;
    }
  }
}
