import {Component, OnInit} from '@angular/core';
import {AppStateService} from 'src/app/core/app-state.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  user: UserDTO;

  constructor(private appState: AppStateService,
              private router: Router
  ) {
  }

  ngOnInit() {
    if (this.appState.userData != null) {
      this.user = this.appState.userData.user
    } else {
      // this.user = {uid: 1111, name: 'Name', username: 'UserName', roles: ['teacher']}
    }

  }

  /*点击二维码*/
  onClickQr() {

  }


}
