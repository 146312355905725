import { Component, OnInit } from '@angular/core';
import { UserInfoService } from '../../core/user-info.service'
import { AppStateService } from 'src/app/core/app-state.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../shared/full-loading/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginData: LoginDTO = { loginName: '', password: '' }
  failed = false

  constructor(private userInfoService: UserInfoService,
              private appState: AppStateService,
              private load: LoadingService,
              private route: Router) {
  }

  ngOnInit() {
  }

  public onSubmit() {
    this.failed = false
    this.load.show(true)
    this.userInfoService.login(this.loginData)
      .subscribe(result => {
        this.load.hide()
        if (!result.success || !result.user) {
          this.failed = true
          return
        }
        this.appState.setUser(result.user)
        this.route.navigate(['man'])
      }, e => {
        this.load.hide()
        this.failed = true
      })
  }
}
