import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {QuestionService} from '../../core/question.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
import {EventManager} from '@angular/platform-browser';
import {max} from 'rxjs/operators';

@Component({
  selector: 'app-review-question-answer',
  templateUrl: './review-question-answer.component.html',
  styleUrls: ['./review-question-answer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewQuestionAnswerComponent implements OnInit, OnChanges {

  @Input() contentList: Array<any>;
  @Input() curContentIndex;
  @Input() sort;
  @Input() curIndex;
  @Input() maxIndex;
  @Input() reviewPaperComponent;
  offsetX: any;
  offsetY: any;
  scaleFactor: any = 1;
  defaultImageWidth: any;
  defaultImageHeight: any;

  constructor(private questionService: QuestionService,
              private eventManager: EventManager,
  ) {}

  ngOnInit() {
    this.refresh();
    this.keyUpEventListenerInit();
  }
  keyUpEventListenerInit() {
    this.eventManager.addGlobalEventListener('window', 'keyup.ArrowUp', () => {
      if ($('app-mark-confirm-dialog').length > 0) {
        return;
      }
      this.previousContent()
    });
    this.eventManager.addGlobalEventListener('window', 'keyup.ArrowDown', () => {
      if ($('app-mark-confirm-dialog').length > 0) {
        return;
      }
      this.nextContent()
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }
  refresh() {
    this.initScaleFactor();
  }
  initScaleFactor() {
    this.scaleFactor = 1;
    $('#imgBox').css({
      width: 'auto',
      height: 'auto',
      left: '',
      top: '',
      'max-width': '100%',
      'max-height': '100%',
    });
  }
  nextContent() {
    if (this.hasNextContent()) {
      this.reviewPaperComponent.nextContent()
      this.initScaleFactor();
    } else {
      this.reviewPaperComponent.confirmNext();
    }
  }
  showNextContentButton() {
    return this.hasNextContent() || this.reviewPaperComponent.hasNext();
  }
  showPreviousContentButton() {
    return this.hasPreviousContent() || this.reviewPaperComponent.hasPrevious();
  }
  previousContent() {
    if (this.hasPreviousContent()) {
      this.reviewPaperComponent.previousContent();
      this.initScaleFactor();
    } else {
      this.reviewPaperComponent.confirmPrevious(true);
    }
  }
  hasNextContent() {
    return this.curContentIndex < this.contentList.length - 1;
  }
  hasPreviousContent() {
    return this.curContentIndex > 0;
  }
  scaleImage(factor: any) {
    if (this.scaleFactor === 1) {
      this.defaultImageWidth = document.getElementById('imgBox').offsetWidth;
      this.defaultImageHeight = document.getElementById('imgBox').offsetHeight;
    }
    this.scaleFactor += factor;
    if (this.scaleFactor <= 0.1) {
      this.scaleFactor = 0.1;
    }
    $('#imgBox').css({
      'width': (this.defaultImageWidth * this.scaleFactor) + 'px',
      'height': (this.defaultImageHeight * this.scaleFactor) + 'px',
      'max-width': (this.defaultImageWidth * this.scaleFactor) + 'px',
      'max-height': (this.defaultImageHeight * this.scaleFactor) + 'px',
    })
  }
  imageMouseDown(event) {
    event.preventDefault(); // 要阻止默认事件，否则拖拽有点小问题
    const div = document.getElementById('imgBox');
    this.offsetX = event.offsetX;
    this.offsetY = event.offsetY;
    const that = this;
    function mouseHandler (e) {
      e.preventDefault();
      if (e.type === 'mousedown' ) {
        that.offsetX = e.offsetX;
        that.offsetY = e.offsetY;
        document.addEventListener('mousemove', mouseHandler);
        document.addEventListener('mouseup',  mouseHandler);
      } else if (e.type === 'mousemove') {
        div.style.left = e.clientX  - that.offsetX + 'px';
        div.style.top = e.clientY - that.offsetY - 121 + 'px';
      } else if (e.type === 'mouseup') {
        document.removeEventListener('mousemove', mouseHandler);
        document.removeEventListener('mouseup',  mouseHandler);
      }
    }
    mouseHandler(event);
  }
}
