import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import { NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {ManageService} from '../../../core/manage.service';
import cnchar from 'cnchar';

@Component({
  selector: 'app-add-or-edit-account',
  templateUrl: './add-or-edit-account.component.html',
  styleUrls: ['./add-or-edit-account.component.scss']
})
export class AddOrEditAccountComponent implements OnInit {
  @Input() modalTitle;
  @Input() editAccountName;
  @Input() editPassword;
  @Input() editUid;
  reviewUserName;
  reviewPassword;
  constructor(
    private ngbModal: NgbModal,
    public activeModal: NgbActiveModal,
    private managerService: ManageService
  ) { }

  ngOnInit() {
    this.reviewUserName = this.editAccountName ? this.editAccountName : null;
    this.reviewPassword = this.editPassword ? this.editPassword : null;
  }

  cancel() {
    this.activeModal.close();
  }

  ok() {
    let data;
    if (this.editAccountName) {
      data = {
        name: this.reviewUserName,
        password: this.getPassword(this.reviewUserName),
        uid: this.editUid
      }
    } else {
      data = {
        name: this.reviewUserName,
        password: this.getPassword(this.reviewUserName)
      }
    }
    this.managerService.updateAccount(data).subscribe(res => {
      this.activeModal.close(true);
    })
  }

  getPassword(name) {
    let filterName = '';
    let flag = true;
    for (const i of name) {
      if (!(_.parseInt(i) >= 0)) {
        filterName += i;
      } else if (flag) {
        filterName += i;
        flag = false;
      }
    }
    try {
      const count = filterName.length < 3 ? filterName.length : 3;
      let result = '';
      for (let i = 0; i < count; i++) {
        result = result.concat(<string>cnchar.spell(filterName.charAt(i), 'first', 'low'));
      }
      return result.concat('0000')
    } catch (e) {
      return '000000';
    }
  }
}
