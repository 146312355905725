import { Component, OnInit } from '@angular/core';
import { ManageService } from '../../core/manage.service';
import { AddOrEditSchoolComponent} from './add-or-edit-school/add-or-edit-school.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {LoadingService} from '../../shared/full-loading/loading.service';
import {MarkConfirmDialogComponent} from '../../mark-paper/mark-confirm-dialog/mark-confirm-dialog.component';

@Component({
  selector: 'app-school-manage',
  templateUrl: './school-manage.component.html',
  styleUrls: ['./school-manage.component.scss']
})
export class SchoolManageComponent implements OnInit {
  schools = [];
  totalCount;
  count = 10;
  currentPage = 1;
  constructor(
    private ngbModal: NgbModal,
    private managerService: ManageService,
    private load: LoadingService,
  ) { }

  ngOnInit() {
    this.loadCount();
    this.loadSchools();
  }

  loadCount() {
    this.managerService.getSchoolCount().subscribe(res => {
      this.totalCount = res;
      if (res > 0) {
        this.loadSchools();
      }
    })
  }

  loadSchools() {
    this.load.show(true);
    this.managerService.getSchoolLists(this.count * (this.currentPage - 1), this.count).subscribe(res => {
      this.load.hide();
      this.schools = res;
    });
    this.load.hide();
  }

  getSubjectsStr(subs) {
    return _.map(subs, 'subjectName').join('，');
  }

  addSchool() {
    const activeModal = this.ngbModal.open(
      AddOrEditSchoolComponent,
      {
        keyboard: false,
        centered: true,
        backdrop: 'static',
        size: 'lg',
        windowClass: 'modal-dialog-school',
        backdropClass: 'modal-backgroud',
      });
    activeModal.componentInstance.modalTitle = '添加学校';
    activeModal.result.then((result) => {
      if (result) {
        this.loadCount();
      }
    })
  }

  editSchool(s) {
    const activeModal = this.ngbModal.open(
      AddOrEditSchoolComponent,
      {
        keyboard: false,
        centered: true,
        backdrop: 'static',
        size: 'lg',
        windowClass: 'modal-dialog-school',
        backdropClass: 'modal-backgroud',
      });
    activeModal.componentInstance.modalTitle = '修改学校';
    activeModal.componentInstance.editSchool = s.school;
    activeModal.componentInstance.editSubjects = s.subjects;
    activeModal.result.then((result) => {
      if (result) {
        this.loadSchools();
      }
    })
  }

  deleteSchool(s) {
    const confirmModal = this.ngbModal.open(MarkConfirmDialogComponent, {centered: true, size: 'sm'});
    confirmModal.componentInstance.config = {
      title: '删除学校',
      message: '确定删除此学校吗？',
      yes: () => {
        this.load.show();
        this.managerService.updateSchoolInfo(s.school.id, []).subscribe(() => {
          this.load.hide();
          confirmModal.close();
          this.loadCount();
        }, (err) => {
          this.load.hide();
          confirmModal.close();
        });
      }
    }
  }

}
