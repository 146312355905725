import {Injectable} from '@angular/core';
import {UserInfoService} from './user-info.service';
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class TopBarService {
  constructor(private userInfoService: UserInfoService) {
  }

  getTabs(): Tab[] {
    const tabs: Tab[] = [];
    tabs.push({name: '编辑', routerPath: '/edit/main'});
    tabs.push({name: '管理', routerPath: '/man'});
    tabs.push({name: '统计', routerPath: '/edit/main'});
    this.userInfoService.currentUserInfo().subscribe(info => {
      if (_.includes(info.roles, 'markerManager')) {
        tabs.push({name: '批改管理', routerPath: '/correct'});
      }
    });
    return tabs;
  }
}

