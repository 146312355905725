import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RayApiService {

  constructor() { }

  public apiA(url: string) {
    return `/ray/api/a/${url}`
  }

  public apiB(url: string) {
    return `/ray/api/b/${url}`
  }
}
