import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {QuestionService} from '../../core/question.service';

@Component({
  selector: 'app-review-question-dialog',
  templateUrl: './review-question-dialog.component.html',
  styleUrls: ['./review-question-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewQuestionDialogComponent implements OnInit {

  @Input()config;
  data: any;

  constructor(
    public activeModal: NgbActiveModal,
    private questionService: QuestionService,
  ) {
  }


  ngOnInit() {
    this.data = this.config.data || {};
    this.config.yes = this.config.yes || this.emptyFun
  }

  emptyFun() {
  }

  yes() {
    this.config.yes()
  }

  no() {
    this.config.no();
  }

}
