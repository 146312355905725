import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-correct',
  templateUrl: './correct.component.html',
  styleUrls: ['./correct.component.scss']
})
export class CorrectComponent implements OnInit {

  constructor() { }
  menus;

  ngOnInit() {
    this.menus = [{
      name: '账号管理',
      icon: 'assets/icon_account.png',
      routerPath: '/correct/account',
    }, {
      name: '阅卷进度',
      icon: 'assets/icon_marking.png',
      routerPath: '/correct/marking',
    }, {
      name: '人员列表',
      icon: 'assets/icon_personnel.png',
      routerPath: '/correct/personnel',
    }, {
      name: '学校管理',
      icon: 'assets/icon_school.png',
      routerPath: '/correct/school',
    }];
  }

}
