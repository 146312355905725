import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { PreModule } from './pre/pre.module'
import { EditingModule } from './editing/editing.module'
import { AuthGuard } from './auth/auth.guard'
import { ManageModule } from './manage/manage.module';
import {MarkPaperModule} from './mark-paper/mark-paper.module'
import { CorrectModule } from './correct/correct.module';

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  redirectTo: 'pre/login',
}, {
  path: 'pre',
  canActivate: [AuthGuard],
  loadChildren: () => PreModule,
}, {
  path: 'edit',
  canActivate: [AuthGuard],
  loadChildren: () => EditingModule,
}, {
  path: 'man',
  canActivate: [AuthGuard],
  loadChildren: () => ManageModule,
}, {
  path: 'mark',
  loadChildren: () => MarkPaperModule
}, {
  path: 'correct',
  canActivate: [AuthGuard],
  loadChildren: () => CorrectModule,
}]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
