import { Component, OnInit, Input} from '@angular/core';
import { ManageService } from '../../../core/manage.service';
import { LoadingService } from '../../../shared/full-loading/loading.service';
import * as _ from 'lodash'
import { zip } from 'rxjs';

@Component({
  selector: 'app-subject-grade',
  templateUrl: './subject-grade.component.html',
  styleUrls: ['./subject-grade.component.scss']
})
export class SubjectGradeComponent implements OnInit {
  globalLgvs: Array<LgvsResult> = []; // 全部学科，学段等id信息
  globalSubjects: Array<SubjectsResult> = []; // 全部学科
  gradeLevels: Array<LevelsResult> = []; // 全部学段
  subjects: Array<SubjectsResult> = []; // 当前显示学科信息
  selectLevelItem: LevelsResult; // 当前选中学段
  selectSubjectItem: SubjectsResult; // 当前选中学科
  @Input() needlinkage: boolean;
  @Input() resource: String;
  constructor(
    private manageService: ManageService,
    private load: LoadingService,
    ) {}

  ngOnInit() {
    this.init();
  }

  public init() {
    if (this.resource === 'outlineSystem') {
      this.manageService.getAllInfoForOutlineSystem()
      .subscribe(([level, subject, lgvs]) => {
        this.gradeLevels = level;
        this.globalSubjects = subject;
        this.globalLgvs = lgvs;
        this.setCurrentLevel(level[0])
      })
    } else {
      this.manageService.getAllInfoForManager()
      .subscribe(([level, subject, version, lgvs]) => {
        this.gradeLevels = level;
        this.globalSubjects = subject;
        this.globalLgvs = lgvs;
        this.setCurrentLevel(level[0])
      })
    }
  };

  public reloadSubject() {
    if (this.needlinkage) {
        // 学科和学段联动
      this.subjects = _.uniq(this.globalLgvs
        .filter(lgv => lgv.levelId === this.selectLevelItem.levelId)
        .map(lgv => lgv.subjectId))
        .map(id => this.globalSubjects.find(sub => id === sub.id));
      this.selectSubjectItem = _.includes(this.subjects, this.selectSubjectItem) ? this.selectSubjectItem : this.subjects[0];
      this.setCurrentSubject(this.selectSubjectItem);
        this.manageService.sendCurrentSelected([this.selectLevelItem, this.selectSubjectItem]);
    } else {
        this.load.hide();
        this.subjects = _.uniq(this.globalLgvs
          .filter(lgv => lgv.levelId === this.selectLevelItem.levelId)
          .map(lgv => lgv.subjectId))
          .map(id => this.globalSubjects.find(sub => id === sub.id));
        this.setCurrentSubject(this.subjects.includes(this.selectSubjectItem) ? this.selectSubjectItem : this.subjects[0])
    }
  }

  public setCurrentLevel(l: LevelsResult) {
    this.load.show(true);
    this.selectLevelItem = l;
    this.reloadSubject();
  };

  public setCurrentSubject(s: SubjectsResult) {
    this.selectSubjectItem = s;
    this.filter();
  }

  public filter () {
    const filterLevels = _.filter(this.globalLgvs, s => s.levelId === this.selectLevelItem.levelId);
    const relId = _.filter(filterLevels, item => item.subjectId === this.selectSubjectItem.id);
    this.manageService.sendCurrentSelected([this.selectLevelItem, this.selectSubjectItem, relId]);
  }


}
