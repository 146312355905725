import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditMainComponent } from './edit-main/edit-main.component';

const routes: Routes = [{
  path: 'main',
  component: EditMainComponent,
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditingRoutingModule { }
