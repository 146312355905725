import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from './ray-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService,
  ) {
  }

  public currentUserInfo(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.rayApi.apiB('login/userinfo1'))
  }

  public login(data: LoginDTO): Observable<LoginResultDTO> {
    return this.httpClient.post<LoginResultDTO>(this.rayApi.apiA('login'), data)
  }
  public markPeoplelogin(data: LoginDTO): Observable<LoginResultDTO> {
    return this.httpClient.post<LoginResultDTO>(this.rayApi.apiA('login?engine=true'), data)
  }
  public logout(): Observable<any> {
    return this.httpClient.get<any>(this.rayApi.apiA('login/logout'));
  }
}
