import { Component, Input, OnInit } from '@angular/core';
import { ManageService } from '../../../core/manage.service';
import * as _ from 'lodash'
import { isUndefined } from 'util';
import { LoadingService } from '../../../shared/full-loading/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-level-to-version',
  templateUrl: './level-to-version.component.html',
  styleUrls: ['./level-to-version.component.scss']
})
export class LevelToVersionComponent implements OnInit {

  gradeLevels: Array<LevelsResult> = []; // 全部学段
  globalSubjects: Array<SubjectsResult> = []; // 全部学科
  globalVersions: Array<VersionResult> = []; // 全部版本

  globalLgvs: Array<LgvsResult> = []; // 对应关系。

  subjects: Array<SubjectsResult> = []; // 当前显示学科信息
  versions: Array<VersionResult> = []; // 当前显示版本信息

  selectLevelItem: LevelsResult = null; // 当前选中学段
  selectSubjectItem: SubjectsResult = null; // 当前选中学科
  selectVersionItem: VersionResult = null; // 当前选中版本
  zlSystem: Boolean = false;
  @Input()
  config: Config;

  constructor(
    private managerService: ManageService,
    private load: LoadingService,
    private router: Router) {
  }

  ngOnInit() {
    if (_.includes(this.router.url, 'zlTextbook')) {
      this.zlSystem = true;
    }
    this.getData()
  }

  getData() {
    if (this.zlSystem) {
      this.managerService.getZlInfo()
      .subscribe((res) => {
        this.gradeLevels = res.map(item => {
          return {levelId: item.vendorLevelId, levelName: item.vendorLevelName, oedlevelId: item.levelId}
        });
        const obj = {};
        let tempStr: any;
        this.gradeLevels = this.gradeLevels.reduce(function(item, next) {
          obj[next.levelId] ? tempStr = '' : obj[next.levelId] = true && item.push(next);
          return item;
        }, []);
        this.globalSubjects = res.map(item => {
          return {id: item.vendorSubjectId, subjectName: item.vendorSubjectName, oedSubjectId: item.subjectId}
        });
        const obj1 = {};
        let tempStr1: any;
        this.globalSubjects = this.globalSubjects.reduce(function(item, next) {
          obj1[next.id] ? tempStr1 = '' : obj1[next.id] = true && item.push(next);
          return item;
       }, []);
        this.globalVersions = res.map(item => {
          return {versionId: item.vendorVersionId, versionName: item.vendorVersionName, oedVersionId: item.versionId}
        });
        const obj2 = {};
        let tempStr2: any;
        this.globalVersions = this.globalVersions.reduce(function(item, next) {
          obj2[next.versionId] ? tempStr2 = '' : obj2[next.versionId] = true && item.push(next);
          return item;
        }, []);
        this.globalLgvs = res.map(item => {
          return {gradeId: item.gradeId, levelId: item.vendorLevelId, relId: item.lgvsId,
            subjectId: item.vendorSubjectId , versionId: item.vendorVersionId}
        });
        this.setCurrentLevel( this.gradeLevels[0])
      })
    } else {
      this.managerService.getAllInfoForManager()
      .subscribe(([level, subject, version, lgvs]) => {
        this.gradeLevels = level;
        this.globalSubjects = subject;
        this.globalVersions = version;
        this.globalLgvs = lgvs;
        this.setCurrentLevel(level[0])
      })
    }
  }

  // 根据已选的学段 加载学科
  private reloadSubject() {
    this.subjects = _.uniq(this.globalLgvs
      .filter(lgv => lgv.levelId === this.selectLevelItem.levelId)
      .map(lgv => lgv.subjectId))
      .map(id => this.globalSubjects.find(sub => id === sub.id));
    this.setCurrentSubject(this.subjects.includes(this.selectSubjectItem) ? this.selectSubjectItem : this.subjects[0])
  }

  // 根据已选的学科 加载版本
  private reloadVersions() {
    this.versions = _.uniq(this.globalLgvs
      .filter(lgv =>
        lgv.levelId === this.selectLevelItem.levelId &&
        lgv.subjectId === this.selectSubjectItem.id &&
        !isUndefined(lgv.versionId))
      .map(lgv => lgv.versionId))
      .map(id => this.globalVersions.find(ver => ver.versionId === id));
    if (this.versions.length === 0) {
      return;
    }
    this.setCurrentVersion(this.versions.includes(this.selectVersionItem) ? this.selectVersionItem : this.versions[0])
  }

  setCurrentLevel(lev: LevelsResult) {
    this.selectLevelItem = lev
    if (this.config != null) {
      this.config.selectLevelItem = this.selectLevelItem
    }
    this.reloadSubject()
  }

  setCurrentSubject(sub: SubjectsResult) {
    this.selectSubjectItem = sub
    if (this.config != null) {
      this.config.selectSubjectItem = this.selectSubjectItem
    }
    this.reloadVersions()
  }

  setCurrentVersion(ver: VersionResult) {
    if (this.config != null) {
      this.config.selectVersionItem = ver
      this.config.onChange()
    }
    this.selectVersionItem = ver
  }

}

export interface Config {
  selectLevelItem?: LevelsResult // 当前选中学段
  selectSubjectItem?: SubjectsResult // 当前选中学科
  selectVersionItem?: VersionResult // 当前选中版本
  onChange();
}
