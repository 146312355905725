import { Component, OnInit } from '@angular/core';
import {NgbDateStruct, NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { ManageService } from '../../core/manage.service';
import {nextDay, toastErrorLoad} from 'src/app/core/utils';
import { ToastrService } from 'ngx-toastr';
import {LoadingService} from '../../shared/full-loading/loading.service';
import {DialogService} from '../../shared/confirm-dialog/dialog.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-marking-progress',
  templateUrl: './marking-progress.component.html',
  styleUrls: ['./marking-progress.component.scss']
})
export class MarkingProgressComponent implements OnInit {
  startDate: NgbDate;
  endDate: NgbDate;
  selectToDay: Boolean = false;
  quesList: Array<any> = [];
  page: any = 1;
  collectionSize: Number;
  pageSize: any = 10;
  totalNum: any; // 用Number不能进行计算
  archived: Boolean = false;
  constructor(
    private calendar: NgbCalendar,
    private managerService: ManageService,
    private load: LoadingService,
    private toast: ToastrService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.endDate = this.calendar.getToday();
    this.startDate = this.calendar.getPrev(this.endDate, 'd', 13);
    this.archived = sessionStorage.getItem('rayEngine_marking_progress_archived') === 'true';
    this.getPaperData(0);
    this.loadCount(0, false)
  }
  loadCount (subjectId, isReload) {
    sessionStorage.setItem('rayEngine_marking_progress_archived', this.archived.toString());
    if (this.page !== 1) {
      this.page = 1;
    }
    const endDate = nextDay(new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`)).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    const archived = this.archived;
    this.managerService.getReviewPaperTotalCount({endDate, startDate, subjectId, archived}).subscribe(
      (res) => {
        this.totalNum = res;
        this.collectionSize = Math.ceil(this.totalNum / this.pageSize) * 10
      }
    );
    if (isReload) {
      this.getPaperData(0)
    }
  }
  getPaperData(subjectId) {
    this.quesList = [];
    const endDate = nextDay(new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`)).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    const pageSize = this.pageSize;
    const startCount = (this.page - 1 ) * pageSize;
    const archived = this.archived;
    this.load.show(true);
    this.managerService.getReviewPaperList({endDate, startDate, subjectId, startCount, pageSize, archived}).subscribe(
      async (res) => {
        const examSessionList = [];
        const examSessionIdList = [];
        _.forEach(res, t => {
          if (t.examSessionId) {
            examSessionIdList.push(t.examSessionId)
          }
        });
        for (const id of _.uniq(examSessionIdList)) {
          const examSession = await this.managerService.getExamSessionById(id).toPromise();
          examSessionList.push(examSession);
        }
        _.forEach(res, t => {
          t.examSession = _.find(examSessionList, es => es.id === t.examSessionId);
        });
         this.load.hide();
        this.quesList = res;
      }, e => {
         this.load.hide();
      }
    )
  }
  checkToday() {
    this.selectToDay = !this.selectToDay;
    if (this.selectToDay) {
      this.startDate = this.calendar.getToday();
      this.endDate = this.calendar.getToday();
    }
  }
  async selectData() {
    const endDate = new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    const dValue = endDate - startDate;
    if (dValue < 0) {
      return   toastErrorLoad(this.toast, '开始日期不能大于结束日期');
    }
    if ( (dValue / (1000 * 60 * 60 * 24) ) > 14) {
      return   toastErrorLoad(this.toast, '查询时间不能大于两周');
    }
    this.loadCount(0, true);
  }
  loadData () {
    this.getPaperData(0);
  }
  archivedPapers(selectItem) {
    this.dialogService.showConfirmDialog('提示', '确定要归档吗?', () => {
      const service = selectItem.previewType === '作业' ?
        this.managerService.testSessionArchive(selectItem.testSessionId, selectItem.questionId)
        : this.managerService.examSessionArchive(selectItem.examSessionId, selectItem.questionId)
      service.subscribe(res => {
        this.loadCount(0, true);
      });
    });
  }
  cancelArchivedPapers(selectItem) {
    this.dialogService.showConfirmDialog( '提示', '确定要取消归档吗?', () => {
      const service = selectItem.previewType === '作业' ?
        this.managerService.testSessionCancelArchive(selectItem.testSessionId, selectItem.questionId)
        : this.managerService.examSessionCancelArchive(selectItem.examSessionId, selectItem.questionId)
      service.subscribe(res => {
        this.loadCount(0, true);
      });
    })
  }
}
