import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditingRoutingModule } from './editing-routing.module';
import { EditMainComponent } from './edit-main/edit-main.component';

@NgModule({
  declarations: [EditMainComponent],
  imports: [
    CommonModule,
    EditingRoutingModule
  ]
})
export class EditingModule { }
