import { ToastrService } from 'ngx-toastr';

export function toastErrorLoad(tService: ToastrService, msg?: string) {
  const errorConf = {
    maxOpened: 1,
    autoDismiss: true,
    closeButton: false,
    positionClass: 'toast-top-center',
    messageClass: 'oed-message-error-text',
    toastClass: 'toast oed-message-error-bg'
  }
  tService.show(msg ? msg : '加载失败，请检查网络。', '', errorConf)
}

export function nextDay(date: Date) {
  date.setDate(date.getDate() + 1);
  return date;
}


