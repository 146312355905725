import {Component, OnInit} from '@angular/core';
import {TopBarService} from '../../core/top-bar.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  constructor(
    private topBarService: TopBarService,
  ) {

  }

  tabs: Tab[];

  ngOnInit() {
    this.tabs = this.topBarService.getTabs()
  }
}

