import { Routes, RouterModule } from '@angular/router';
import { CorrectComponent } from './correct.component';
import { AccountManageComponent } from './account-manage/account-manage.component';
import { MarkingProgressComponent } from './marking-progress/marking-progress.component';
import { PersonnelListComponent } from './personnel-list/personnel-list.component';
import { SchoolManageComponent } from './school-manage/school-manage.component';

const routes: Routes = [{
  path: '',
  component: CorrectComponent,
  children: [
    {
      path: 'account',
      component: AccountManageComponent
    },
    {
      path: 'marking',
      component: MarkingProgressComponent
    },
    {
      path: 'personnel',
      component: PersonnelListComponent
    },
    {
      path: 'school',
      component: SchoolManageComponent
    },
    {path: '', redirectTo: '/correct/account'},
  ]
}];

export const CorrecrRoutingRoutes = RouterModule.forChild(routes);
