import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mark-confirm-dialog',
  templateUrl: './mark-confirm-dialog.component.html',
  styleUrls: ['./mark-confirm-dialog.component.scss']
})
export class MarkConfirmDialogComponent implements OnInit {

  @Input()
  config: MarkConfirmDialogConfig;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.config.title = this.config.title || '确定？';
    this.config.message = this.config.message || '确定关闭吗?';
    this.config.yesText = this.config.yesText || '是';
    this.config.noText = this.config.noText || '否';
    this.config.yes = this.config.yes || this.emptyFun;
    this.config.no = this.config.no || this.emptyFun;
    this.config.hideNoButton = this.config.hideNoButton || false;
  }

  emptyFun() {
  }

  yes() {
    this.config.yes()
    this.activeModal.close()
  }

  no() {
    this.config.no();
    this.activeModal.close();
  }
}

export interface MarkConfirmDialogConfig {
  title?: string;
  message?: string;
  yesText?: string;
  noText?: string;
  hideNoButton?: boolean;
  yes?()

  no?()
}
