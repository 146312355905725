import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ManageRoutingModule } from './manage-routing.module';
import { KnowledgeOutlineComponent } from './knowledge-outline/knowledge-outline.component';
import { ManageComponent } from './manage/manage.component';
import { SharedModule } from '../shared/shared.module';
import { SubjectGradeComponent } from './knowledge-outline/subject-grade/subject-grade.component';
import { LevelTitleComponent } from './knowledge-outline/level-title/level-title.component';
import { TreeNodeComponent } from './knowledge-outline/tree-node/tree-node.component';
import { KnowledgeTextbookComponent } from './knowledge-textbook/knowledge-textbook.component';
import { LevelToVersionComponent } from './general/level-to-version/level-to-version.component';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BookLinkKnowledgeComponent } from './knowledge-textbook/book-link-knowledge/book-link-knowledge.component';
import { TreeModule } from 'angular-tree-component';
import { KnowledgeSystemKnowledgeComponent } from './knowledge-system-knowledge/knowledge-system-knowledge.component';
import { KnowledgeTitleComponent } from './knowledge-system-knowledge/knowledge-title/knowledge-title.component';

@NgModule({
  declarations: [
    KnowledgeOutlineComponent,
    ManageComponent,
    SubjectGradeComponent,
    LevelTitleComponent,
    KnowledgeTextbookComponent,
    LevelToVersionComponent,
    BookLinkKnowledgeComponent,
    TreeNodeComponent,
    KnowledgeSystemKnowledgeComponent,
    KnowledgeTitleComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ManageRoutingModule,
    FormsModule,
    NgbModule,
    NgbModalModule,
    ManageRoutingModule,
    TreeModule,
  ],
  entryComponents: [
    BookLinkKnowledgeComponent
  ]
})
export class ManageModule {}
