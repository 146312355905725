import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from './ray-api.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import {ManageService} from './manage.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  map = {
    'singlechoice': '单选',
    'yesorno': '判断',
    'multichoice': '多选',
    'connect': '匹配',
    'fill': '填空',
    'singlevote': '单选投票',
    'qa': '问答题',
    'multivote': '多选投票'
  };
  choiceArr = ['A：', 'B：', 'C：', 'D：', 'E：', 'F：', 'G：', 'H：', 'I：', 'J：', 'K：', 'L：',
    'M：', 'N：', 'O：', 'P：', 'Q：', 'R：', 'S：', 'T：', 'U：', 'Y：', 'W：', 'X：', 'Y：', 'Z：'];

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService,
              private managerService: ManageService) {}

  public currentUserInfo(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.rayApi.apiB('login/userinfo1'))
  }

  public getKnowPoint(question, reviewType) {
    // 考试使用软云知识点
    if (reviewType === 1) {
      let result = '';
      for (const t of question.ryKnowledgePoints) {
        result += '，' + t.name;
      }
      return result.slice(1);
    }

    const relatedSections = question.relatedSections;
    if (relatedSections == null) {
      return '';
    }
    let strPoint = '';
    for (let index = 0; index < relatedSections.length; index++) {
      const element = relatedSections[index];
      if (element.id === relatedSections[0].id && relatedSections.length === 1) {
        strPoint += element.sectionName;
      } else {
        if (element.id === relatedSections[relatedSections.length - 1].id) {
          strPoint += element.sectionName;
        } else {
          strPoint += element.sectionName + '，';
        }
      }
    }
    if (relatedSections.length === 0) {
      strPoint = '无';
    }
    return strPoint;
  }
  public getQuesType(type) {
    return this.map[type];
  }
  public getChoiceName(i) {
    return this.choiceArr[i];
  }
  public getImgUrl(uuid) {
    return `/ray/resource/uuid/${uuid}/src`
  }
  public isEmpty(str) {
    return _.isEmpty(_.trim(str));
  }
}
