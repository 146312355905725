import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() {
  }

  getMenu(): MenuDTO[] {
    const menus: MenuDTO[] = [];
    menus.push({
      level: 1, name: '大纲体系', icon: 'assets/icon_course.png',
      routerPath: '/man/knowledge',
      childMenu: [
        {level: 2, name: '大纲关联', routerPath: '/man/knowledge/outline'},
        {level: 2, name: '锐学堂教材关联', routerPath: '/man/knowledge/textbook'},
        {level: 2, name: '中联教材关联', routerPath: '/man/knowledge/zlTextbook'},
        {level: 2, name: '软云教材关联', routerPath: '/man/knowledge/ryTextbook'}]
    });
    menus.push({
      level: 1, name: '知识点体系', icon: 'assets/icon_course.png',
      routerPath: '/man/knowledgeSystem',
      childMenu: [
        {level: 2, name: '知识点', routerPath: '/man/knowledgeSystem/knowledge'},
        {level: 2, name: '知识点关联', routerPath: '/man/knowledgeSystem/knowledgeRelation'}]
    });
    menus.push({
      level: 1, name: '课程体系', icon: 'assets/icon_knowledge.png',
      routerPath: '/man/course',
      childMenu: [
        {level: 2, name: '课程模版', routerPath: '/man/course/template'},
        {level: 2, name: '知识点', routerPath: '/man/course/knowledge'}]
    });
    menus.push({
      level: 1, name: '能力素养', icon: 'assets/icon_capacity.png',
      routerPath: '/man/ability',
      childMenu: [
        {level: 2, name: '能力', routerPath: '/man/ability/base'},
        {level: 2, name: '学科核心素养', routerPath: '/man/ability/core'}]
    });
    return menus
  }
}
