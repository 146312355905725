import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageComponent } from './manage/manage.component';
import { KnowledgeOutlineComponent } from './knowledge-outline/knowledge-outline.component';
import { KnowledgeTextbookComponent } from './knowledge-textbook/knowledge-textbook.component';
import { KnowledgeSystemKnowledgeComponent } from './knowledge-system-knowledge/knowledge-system-knowledge.component';

const routes: Routes = [{
  path: '',
  component: ManageComponent,
  children: [
    {
      path: 'knowledge',
      children: [
        {path: 'outline', component: KnowledgeOutlineComponent},
        {path: 'textbook', component: KnowledgeTextbookComponent},
        {path: 'zlTextbook', component: KnowledgeTextbookComponent},
        {path: 'ryTextbook', component: KnowledgeTextbookComponent},
        {path: '', redirectTo: '/man/knowledge/outline'},
      ]
    },
    {
      path: 'course',
      children: [
        {path: 'template', component: null},
        {path: 'knowledge', component: null},
        {path: '', redirectTo: '/man/course/template'},
      ]
    },
    {
      path: 'ability',
      children: [
        {path: 'base', component: null},
        {path: 'core', component: null},
        {path: '', redirectTo: '/man/ability/base'},
      ]
    },
    {
      path: 'knowledgeSystem',
      children: [
        {path: 'knowledge', component: KnowledgeSystemKnowledgeComponent},
        {path: 'knowledgeRelation', component: KnowledgeTextbookComponent},
        {path: '', redirectTo: '/man/knowledgeSystem/knowledge'},
      ]
    },
    {path: '', redirectTo: '/man/knowledge/outline'},
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageRoutingModule {
}
