import { Component, OnInit } from '@angular/core';
import {NgbCalendar, NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IConfirmConfig} from '../../shared/confirm-dialog/confirm-dialog.component';
import {ManageService} from '../../core/manage.service';
import {LoadingService} from '../../shared/full-loading/loading.service';
import {nextDay, toastErrorLoad} from '../../core/utils';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-personnel-list',
  templateUrl: './personnel-list.component.html',
  styleUrls: ['./personnel-list.component.scss']
})
export class PersonnelListComponent implements OnInit {
  startDate: NgbDate;
  endDate: NgbDate;
  selectToDay: Boolean = false;
  personalLists = [];
  totalCount;
  count = 10;
  currentPage = 1;
  constructor(
    private calendar: NgbCalendar,
    private managerService: ManageService,
    private load: LoadingService,
    private toast: ToastrService
  ) { }

  ngOnInit() {
    this.endDate = this.calendar.getToday();
    this.startDate = this.calendar.getPrev(this.endDate, 'd', 13);
    this.loadCount();
    // this.loadPersonals();
  }

  loadCount() {
    const endDate = nextDay(new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`)).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    const dValue = endDate - startDate;
    if (dValue < 0) {
      return   toastErrorLoad(this.toast, '开始日期不能大于结束日期');
    }
    if ( (dValue / (1000 * 60 * 60 * 24) ) > 14) {
      return   toastErrorLoad(this.toast, '查询时间不能大于两周');
    }
    const data = {
      startTime: startDate,
      endTime: endDate,
    };
    this.managerService.getPersonalCount(data).subscribe(res => {
      this.totalCount = res;
      if (res > 0) {
        this.loadPersonals();
      } else {
        this.personalLists = [];
      }
    })
  }

  loadPersonals() {
    const endDate = nextDay(new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`)).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    const data = {
      reviewId: 1,
      startTime: startDate,
      endTime: endDate,
      form: this.count * (this.currentPage - 1),
      count: this.count
    };
    this.managerService.getPersonalList(data).subscribe(res => {
      this.personalLists = res;
    })
  }

  checkToday() {
    this.selectToDay = !this.selectToDay;
    if (this.selectToDay) {
      this.startDate = this.calendar.getToday();
      this.endDate = this.calendar.getToday();
    }
  }
  checkDayIsToday() {
    const toDay = this.calendar.getToday();
    const todayDate = new Date(`${toDay.year}-${toDay.month}-${toDay.day}`).getTime();
    const endDate = new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    if (todayDate ===  endDate && todayDate ===  startDate ) {
      this.selectToDay = true;
    } else {
      this.selectToDay = false;
    }
  }
}
