import { Injectable } from '@angular/core';
import { UserInfoService } from './user-info.service';
import { finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  ready = false
  userData: UserData = null
  userDataSubject = new Subject<UserData>()

  constructor(private userInfoService: UserInfoService, private router: Router) {
    userInfoService.currentUserInfo()
      .pipe(finalize(() => {
        this.ready = true
        this.userDataSubject.next(this.userData)
        this.userDataSubject.complete()
      })).subscribe(
      info => this.userData = this.getUserData(info),
      e => {
      })
  }

  public clearUserData() {
    this.userData = null
  }

  public isReady() {
    return this.ready
  }

  private getUserData(user: UserDTO | null): UserData {
    if (user == null) {
      this.userData = null
      return
    }
    const roles = _(user.roles).split(',').keyBy().value()
    return {
      user,
      roles,
    }
  }

  public setUser(user: UserDTO) {
    this.userData = this.getUserData(user)
  }

  public logout() {
    this.clearUserData();
    this.userInfoService
      .logout()
      .pipe(finalize(() => {
        this.router.navigate(['pre', 'login']);
      })).subscribe();

  }
}
