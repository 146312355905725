import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  @Input()
  config: IConfirmConfig;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }


  ngOnInit() {
    this.config.title = this.config.title || '确定？'
    this.config.message = this.config.message || '确定关闭吗?'
    this.config.yesText = this.config.yesText || '是'
    this.config.noText = this.config.noText || '否'
    this.config.yes = this.config.yes || this.emptyFun
    this.config.no = this.config.no || this.emptyFun
  }

  emptyFun() {
  }

  yes() {
    this.config.yes()
    // this.activeModal.close()
  }

  no() {
    this.config.no();
    this.activeModal.close();
  }
}

export interface IConfirmConfig {
  title?: string;
  message?: string;
  yesText?: string;
  noText?: string;

  yes?()

  no?()
}
