import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoComponent } from './user-info/user-info.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { RouterModule } from '@angular/router';
import { TopToolsRightComponent } from './top-tools-right/top-tools-right.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {NgbModalModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { FullLoadingComponent } from './full-loading/full-loading.component';
import {MarkTableComponent} from '../mark-paper/mark-table/mark-table.component'
import {MarkConfirmDialogComponent} from '../mark-paper/mark-confirm-dialog/mark-confirm-dialog.component';
@NgModule({
  declarations: [
    UserInfoComponent,
    TopBarComponent,
    SideBarComponent,
    SideNavBarComponent,
    TopToolsRightComponent,
    ConfirmDialogComponent,
    MarkTableComponent,
    FullLoadingComponent,
    MarkConfirmDialogComponent],
  exports: [
    UserInfoComponent,
    TopBarComponent,
    SideBarComponent,
    MarkTableComponent,
    SideNavBarComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModalModule,
    NgbTooltipModule,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    FullLoadingComponent,
    MarkConfirmDialogComponent
  ]
})
export class SharedModule {
}
