import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-review-answer-dialog',
  templateUrl: './review-answer-dialog.component.html',
  styleUrls: ['./review-answer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewAnswerDialogComponent implements OnInit {

  @Input()config: ReviewDialogConfig;
  childQuestions: Array<any> = [];
  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }


  ngOnInit() {
    this.config.title = this.config.title
    this.config.message = this.config.message || ''
    this.config.yesText = this.config.yesText || '确定'
    this.childQuestions = this.config.data ? this.config.data.childQuestions : []
    this.config.type = this.config.type || ''
    this.config.yes = this.config.yes || this.emptyFun
  }

  emptyFun() {
  }

  yes() {
    this.config.yes()
  }

  no() {
    this.config.no();
  }
}

export interface ReviewDialogConfig {
  title?: string;
  message?: string;
  yesText?: string;
  data?: any;
  type?: string
  yes?()

  no?()

}
