import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from './ray-api.service';
import { Observable, ReplaySubject, zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageService {

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService) {
  }

  private _sendMessage: ReplaySubject<any> = new ReplaySubject<any>(1);

  // 获取全部学段信息
  public getAllLevels(): Observable<LevelsResult[]> {
    return this.httpClient.get<LevelsResult[]>(this.rayApi.apiB('coursepath/levels/all'))
  }

  // 获取全部学段，学科，版本等id信息
  public getAllLgvs(): Observable<LgvsResult[]> {
    return this.httpClient.get<LgvsResult[]>(this.rayApi.apiB('outline/ls/list'))
  }

  // 获取全部学段，学科，版本等id信息
  public getLgvs(): Observable<LgvsResult[]> {
    return this.httpClient.get<LgvsResult[]>(this.rayApi.apiB('coursepath/lgvs/all'))
  }

  // 获取全部学科信息
  public getAllSubjects(): Observable<SubjectsResult[]> {
    return this.httpClient.get<SubjectsResult[]>(this.rayApi.apiB('coursepath/subjects/all'))
  }

  // 更新当前选中学段,学科等信息
  public sendCurrentSelected(current: any): Observable<any> {
    this._sendMessage.next(current);
    return;
  }

  // 获取当前选中学段,学科等信息
  public getCurrentSelected(): Observable<any> {
    return this._sendMessage.asObservable();
  }
  // 根据 levelId和subjectId, 获取所有的outlines
  public getOutlines(levelId: number, subjectId: number): Observable<OutlinesResult[]> {
    return this.httpClient.get<OutlinesResult[]>(this.rayApi.apiB(`outline/level/${levelId}/subject/${subjectId}/list`));
  }

  // 根据 relId, 获取所有的知识点
  public getknowledges(relId: number): Observable<OutlinesResult[]> {
    return this.httpClient.get<OutlinesResult[]>(this.rayApi.apiB(`globalKnowledgePoint/globalKnowledgePointForBook/${relId}/list`));
  }

  // 根据 subjectId和levelId, 获取知识点
  public getknowledgesBySubjectIdAndLevelId(subjectId: number, levelId: number): Observable<OutlinesResult[]> {
    return this.httpClient.get<OutlinesResult[]>(this.rayApi.apiB(`globalKnowledgePoint/subject/${subjectId}/level/${levelId}`));
  }

  // 根据 subjectId和levelId, 获取知识点
  public getRYKnowledgesBySubjectIdAndLevelId(subjectId: number, levelId: number): Observable<any> {
    return this.httpClient.get<any>(this.rayApi.apiB(
      `mapping/ryKnowledgePoint/levelId/${levelId}/subjectId/${subjectId}`));
  }

  // 删除大纲节点
  public deleteNode(id: number): Observable<OutlinesResult> {
    return this.httpClient.delete<any>(this.rayApi.apiB(`outline/${id}`));
  }

  // 新建大纲节点
  public addNode(data: AddSection): Observable<any> {
    return this.httpClient.post<AddSection>(this.rayApi.apiB('outline'), data);
  }

  // 更新大纲节点
  public updateNode(data: { name: string, sort: number }, id: number): Observable<any> {
    return this.httpClient.post<{ name: string, sort: number }>(this.rayApi.apiB(`outline/${id}`), data);
  }

  // 更新大纲节点位置
  public updateNodeSort(data: [{ id: number, sort: number }, { id: number, sort: number }]): Observable<any> {
    return this.httpClient.post<[{ id: number, sort: number }, { id: number, sort: number }]>
    (this.rayApi.apiB(`outline/updateorders`), data);
  }

  // 更新知识点节点
  public updateKnowledgeNode(data): Observable<any> {
    return this.httpClient.post<any>
    (this.rayApi.apiB(`mapping/ryKnowledgePoint/update`), data);
  }
  // 新建知识点节点
  public addKnowledgeNode(data: { pid: number, name: string, levelId: number, subjectId: number }): Observable<any> {
    return this.httpClient.post<{ pid: number, name: string, levelId: number,
      subjectId: number }>(this.rayApi.apiB('mapping/ryKnowledgePoint/create'), data);
  }

  // 版本信息
  public getAllVersions(): Observable<VersionResult[]> {
    return this.httpClient.get<VersionResult[]>(this.rayApi.apiB('coursepath/versions/all'))

  }

  // 教材信息
  public getAllBooks(): Observable<BookResult[]> {
    return this.httpClient.get<BookResult[]>(this.rayApi.apiB('coursepath/books/all'))
  }

  // 软云教材信息
  public getRanyunBooks(type: String): Observable<any> {
    return this.httpClient.get<any>(this.rayApi.apiB(`mapping/all/${type}`))
  }

  // 获取学段 学科 版本 及其关系
  public getAllInfoForManager(): Observable<[LevelsResult[], SubjectsResult[], VersionResult[], LgvsResult[]]> {
    return zip(this.getAllLevels(), this.getAllSubjects(), this.getAllVersions(), this.getLgvs())
  }
  // 获取大纲体系学段 学科
  public getAllInfoForOutlineSystem(): Observable<[LevelsResult[], SubjectsResult[], LgvsResult[]]> {
    return zip(this.getAllLevels(), this.getAllSubjects(), this.getLgvs())
  }
  // 获取章节
  public getBookChapter(grade: number, subject: number, version: number): Observable<BookChapterLinkResult[]> {
    return this.httpClient.get<BookChapterLinkResult[]>(
      this.rayApi.apiA('materialversion/' + version + '/subject/' + subject + '/grade/' + grade + '/units'))
  }

  // 获取课程关联的大纲 outline/section/4139774/list
  public getOutlineSection(relId: string): Observable<OutlineSection[]> {
    return this.httpClient.get<OutlineSection[]>(this.rayApi.apiB('outline/relForSection/' + relId + '/list'))
  }
  public getzlOutlineSection(relId: string): Observable<OutlineSection[]> {
    return this.httpClient.get<OutlineSection[]>(this.rayApi.apiB('mapping/zlRels/' + relId))
  }
  public getRYOutlineSection(levelId: number, subjectId: number, gradeId: number, versionId: number): Observable<OutlineSection[]> {
    return this.httpClient.get<OutlineSection[]>(this.rayApi.apiB(
      `outline/mappedChapters/vendor/ruanyun/level/${levelId}/subject/${subjectId}/grade/${gradeId}/version/${versionId}/section/list`))
  }
  // 获取大纲知识列表 outline/level/697503/subject/1488365/list
  public getOutlineKnowledge(level: number, subject: number): Observable<KnowledgeResult[]> {
    return this.httpClient.get<KnowledgeResult[]>(this.rayApi.apiB('outline/level/' + level + '/subject/' + subject + '/list'))
  }

  // 获取软云知识列表
  public getRYKnowledge(relId: string): Observable<any> {
    return this.httpClient.get<any>(this.rayApi.apiB(`outline/ruanyun/chapters/lgvs/${relId}`))
  }


  // 关联 outline/section/relate
  public ralateSectionOutline(sectionId: number, knowledges: KnowledgeResult[]): Observable<OutlineSection[]> {
    if (knowledges.length === 0) {
      return
    }
    const data = knowledges.map(k => {
      return {sectionId: sectionId, outlineId: k.id}
    });
    return this.httpClient.post<OutlineSection[]>(this.rayApi.apiB('outline/section/relate'), data)
  }

  //
  public deleteSectionOutline(lindDataId: number, sectionId: number, knowledge: KnowledgeResult): Observable<OutlineSection[]> {
    const data = [knowledge].map(k => {
      return {id: lindDataId, sectionId: sectionId, outlineId: k.id, deleted: true}
    });
    return this.httpClient.post<OutlineSection[]>(this.rayApi.apiB('outline/section/relate'), data)
  }

  // 关联知识点 globalKnowledgePoint/section/relate
  public ralateSectionKnowledge(sectionId: number, knowledges: KnowledgeResult[]): Observable<OutlineSection[]> {
    if (knowledges.length === 0) {
      return
    }
    const data = knowledges.map(k => {
      return {sectionId: sectionId, knowledgePointId: k.id}
    });
    return this.httpClient.post<OutlineSection[]>(this.rayApi.apiB('globalKnowledgePoint/section/relate'), data)
  }

  //  取消关联知识点
  public deleteSectionKnowledge(lindDataId: number, sectionId: number, knowledge: KnowledgeResult): Observable<OutlineSection[]> {
    const data = [knowledge].map(k => {
      return {id: lindDataId, sectionId: sectionId, knowledgePointId: k.id, deleted: true}
    });
    return this.httpClient.post<OutlineSection[]>(this.rayApi.apiB('globalKnowledgePoint/section/relate'), data)
  }

  // 获取教材关联的信息
  public getCoursePathRel(relId: string): Observable<CoursePathRel[]> {
    return this.httpClient.get<CoursePathRel[]>(this.rayApi.apiB('coursepath/relId/' + relId))
  }

  //
  public deleteBook(relId: string): Observable<any> {
    return this.httpClient.delete<any>(this.rayApi.apiB('coursepath/book/' + relId))
  }

  // 获取教材关联状态
  public getBookLinkStatus(relIds: string[]): Observable<BookLinkStatus[]> {
    return this.httpClient.get<BookLinkStatus[]>(
      this.rayApi.apiB('coursepath/relId/statusByIds'), {params: {ids: relIds}});
  }
  // 获取中联教材关联状态
  public getzlBookLinkStatus(relIds: any): Observable<any[]> {
    return this.httpClient.post<any[]>(
      this.rayApi.apiB('mapping/states'), relIds);
  }
  // 获取软云教材关联状态
  public getRYBookLinkStatus(relIds: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      this.rayApi.apiB('coursepath/relId/vendorChapterStatusByIds?vendor=ruanyun' + relIds));
  }
  // 获取中联章节
  public getzlBookChapter(bookId: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      this.rayApi.apiB('zlChapters/book/' + bookId));
  }
  // 获取中联相关
  public getZlInfo(): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB('mapping/all/zlwh'));
  }
  public updateZlRelate(data): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB('outline/zlChapter/relate'), data);
  }
  public updateRYRelate(data): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB('globalKnowledgePoint/rySection/relate'), data);
  }
  public batchDeleteRelate(data): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB('outline/zl/outlineChapter/relates/batchDelete'), data);
  }
  public getSubjectList(): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiA('school/0/subjects?vendor=Oed'));
  }
  public getReviewPaperList(data): Observable<any> {
    return this.httpClient.get<any>(
      // tslint:disable-next-line: max-line-length
      this.rayApi.apiB(`preview/list?schoolId=0&subjectId=${data.subjectId}&from=${data.startDate}&to=${data.endDate}&start=${data.startCount}&count=${data.pageSize}&archived=${data.archived}`));
  }
  public getStuAnswerList(data): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`testsession/${data.testSessionId}/question/${data.questionId}/review/answers`));
  }
  public getStuAnswerListByExam(data): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`examAnswer/review/session/${data.sessionId}/question/${data.questionId}/answers`));
  }
  // 获取区域和学校列表
  public getAreaAndSchools(): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB('school/locations'));
  }
  // 获取学科列表
  public getSubjects(schoolId): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiA(`school/${schoolId}/subjects?vendor=Oed`));
  }
  // 获取学校数量
  public getSchoolCount(): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB('preview/schools/count'));
  }
  // 获取学校列表
  public getSchoolLists(start, count): Observable<any> {
    return this.httpClient.get<any>(
    this.rayApi.apiB(`preview/schools?start=${start}&count=${count}`));
  }
  // 修改/添加/删除学校
  public updateSchoolInfo(schoolId, data, add?): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`preview/school/${schoolId}/update?add=${add || false}`), data);
  }
  // 获取账号总数
  public getAccountCount(): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB('marker/count'));
  }
  // 获取账号列表
  public getAccountLists(start, count): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`marker/from/${start}/count/${count}`));
  }
  // 修改/添加账号
  public updateAccount(data): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB('/marker/upsert'), data);
  }
  // 删除账号
  public deleteAccount(uid): Observable<any> {
    return this.httpClient.delete<any>(
      this.rayApi.apiB(`marker/${uid}`));
  }
  public getQuestionInfo(questionId): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`objectivequestion/${questionId}`));
  }
  public getReviewPaperTotalCount(data): Observable<any> {
    return this.httpClient.get<any>(
      // tslint:disable-next-line: max-line-length
      this.rayApi.apiB(`preview/list/count?schoolId=0&subjectId=${data.subjectId}&from=${data.startDate}&to=${data.endDate}&archived=${data.archived}`));
  }
  // 获取人员列表总数
  public getPersonalCount(data): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB('testAnswer/review/count'), data);
  }
  // 获取人员列表
  public getPersonalList(data): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB('testAnswer/review/list'), data);
  }
  // 作业归档
  public testSessionArchive(testSessionId, questionId): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`preview/archive/testSession/${testSessionId}/question/${questionId}`), {});
  }
  // 作业取消归档
  public testSessionCancelArchive(testSessionId, questionId): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`preview/cancelArchive/testSession/${testSessionId}/question/${questionId}`), {});
  }
  // 考试归档
  public examSessionArchive(examSessionId, questionId): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`preview/archive/examSession/${examSessionId}/question/${questionId}`), {});
  }
  // 考试取消归档
  public examSessionCancelArchive(examSessionId, questionId): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`preview/cancelArchive/examSession/${examSessionId}/question/${questionId}`), {});
  }
  public getQuestStatus(data): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`testAnswerReview/start`), data);
  }
  // 批阅
  public testAnswerReview(data): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`testAnswerReview`), data);
  }
  // 更新考试统计
  public endExamSessionReview(examSessionId): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`examSession/${examSessionId}/endReview`), {});
  }
  // 获取软云教材信息
  public getRyCourseMappingBySubjectId(subjectId): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`outline/ruanyun/courseMapping/subject/${subjectId}`));
  }
  // 更新软云教材映射
  public updateRyBookMapping(relId, ryCourseMappingId): Observable<any> {
    return this.httpClient.post<any>(
      this.rayApi.apiB(`outline/ruanyun/bookMapping/lgvs/${relId}/ryCourseMapping/${ryCourseMappingId}`), {});
  }
  // 获取软云教材映射
  public getRyBookMapping(subjectId, versionId, gradeId): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`/mapping/ruanyun/subject/${subjectId}/version/${versionId}/grade/${gradeId}`));
  }
  // 获取作业标签
  public getQuestionTagsByQuestionId(questionId): Observable<QuestionTag[]> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`question/${questionId}/tags`));
  }
  // 获取知识点
  public getKnowledgePointsById(ids): Observable<KnowledgeResult[]> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`/mapping/ryKnowledgePoint`), {params: {id: ids}});
    // 获取考试信息
  }
  public getExamSessionById(examSessionid): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`/examSession/${examSessionid}`));
  }
}
