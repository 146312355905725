import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ITreeOptions, TreeComponent, TreeModel, TreeNode } from 'angular-tree-component';
import { ManageService } from '../../../core/manage.service';
import { zip, } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { LoadingService } from '../../../shared/full-loading/loading.service';
import { ToastrService } from 'ngx-toastr';
import { toastErrorLoad } from '../../../core/utils';
import * as _ from 'lodash';
import * as $ from 'jquery';
import {DialogService} from '../../../shared/confirm-dialog/dialog.service';

@Component({
  selector: 'app-book-link-knowledge',
  templateUrl: './book-link-knowledge.component.html',
  styleUrls: ['./book-link-knowledge.component.scss']
})
export class BookLinkKnowledgeComponent implements OnInit, AfterViewInit {

  @Input()
  mConfig: BookLinkConfig;
  items: Array<BookChapterLinkResult> = [];
  editBCLR: BookChapterLinkResult = null;
  linkData: Array<OutlineSection> = [];
  options: ITreeOptions = {  // 树型结构组件的配置
    nodeHeight: 18,
    animateExpand: true,
    scrollOnActivate: false,
    useCheckbox: true,
    useTriState: false,
    levelPadding: 30,
    nodeClass: (node: TreeNode) => {
      return 'nodeId-' + node.id;
    },
    actionMapping: {
      mouse: {
        checkboxClick: (a, b, c) => this.clickCheckBox(a, b, c)
      },
    }
  };

  nodes: KnowledgeResult[]; // 大纲知识点数据 用于下拉选择

  // 用于软云教材匹配
  ryCourseMappings: RyCourseMapping[] = [];

  ryVersions: RyCourseMapping[] = [];
  ryBooks: RyCourseMapping[] = [];
  selectedRyVersion: RyCourseMapping;
  selectedRyBook: RyCourseMapping;
  submittedRyBook: RyCourseMapping;

  @ViewChild('dropMenu')
  private dropMenu: ElementRef<HTMLDivElement>;
  @ViewChildren(TreeComponent)
  private treeQuery!: QueryList<TreeComponent>;

  constructor(
    public activeModal: NgbActiveModal,
    private loadService: LoadingService,
    private toast: ToastrService,
    private managerService: ManageService,
    private dialogService: DialogService) {
  }

  offsetClass(s: BookChapterLinkResult): any {
    return { 'level-ml-2': s.depth === 2, 'level-ml-3': s.depth === 3, 'level-ml-4': s.depth === 4 }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getData()
    if (this.mConfig.rySystem) {
      this.loadRyCourseMappings()
    }
  }

  onClickToOutline() {

  }

  async loadRyCourseMappings() {
    this.ryCourseMappings = await this.managerService.getRyCourseMappingBySubjectId(this.mConfig.subjectId).toPromise();
    this.ryVersions = _.uniqBy(this.ryCourseMappings, t => t.versionId);
    const ryBookMapping = await this.managerService.getRyBookMapping(
      this.mConfig.subjectId, this.mConfig.versionId, this.mConfig.editBook.gradeId).toPromise();
    this.selectedRyVersion = _.find(this.ryCourseMappings, (t => t.versionId.toString() === ryBookMapping.vendorVersionId));
    this.ryBooks = _.filter(this.ryCourseMappings, t => t.versionId === this.selectedRyVersion.versionId);
    this.selectedRyBook = _.find(this.ryCourseMappings, (t => t.id.toString() === ryBookMapping.vendorBookId ));
    this.submittedRyBook = this.selectedRyBook;
  }

  // 下拉选择框 显示隐藏
  onDropChange(ngbDrop: NgbDropdown) {
    if (this.treeQuery.length === 0) {
      return
    }
    if (ngbDrop.isOpen()) {
      // 这里更新选中状态。
      this.updateCheckBox()

    } else {
      this.editBCLR = null
    }
  }

  // 更新CheckBox 状态 的递归方法
  private selectedChangedForTree(roots) {
    roots.forEach(tn => {
      const isSelected = this.editBCLR.knowledge.includes(tn);
      if (isSelected) {
        const domList = $('.nodeId-' + tn.id + ' .node-wrapper .tree-node-checkbox');
        domList[0]['checked'] = true;
      }
      if (tn.children && tn.children.length > 0) {
        this.selectedChangedForTree(tn.children)
      }
    }
    )
  }

  // 更新CheckBox 状态
  private updateCheckBox() {
    if (this.treeQuery.length === 0) {
      return
    }
    // const treeComponent = this.treeQuery.first;
    // this.selectedChangedForTree(treeComponent.treeModel.roots)
    this.selectedChangedForTree(this.nodes)
  }

  // 点击了CheckBox
  clickCheckBox(tree: TreeModel, node: TreeNode, $e: any) {

    if (this.editBCLR.knowledge.includes(node.data)) {
      node.setIsSelected(false);
      this.deleteFromSever(node.data);
      this.editBCLR.knowledge = this.editBCLR.knowledge.filter(it => it.id !== node.data.id)
    } else {
      node.setIsSelected(true);
      this.saveToSever(node.data);
      this.editBCLR.knowledge.push(node.data)
    }
    this.updateKnowledgeText(this.editBCLR)
  }

  // 显示编辑
  showEdit(bclr: BookChapterLinkResult) {
    this.editBCLR = bclr
  }


  isEmpty(array?: Array<any>) {
    return array == null || array.length === 0
  }

  private getData() {
    this.loadService.show();
    let left: any;
    if (this.mConfig.zlSystem) {
      left = this.managerService.getzlBookChapter(this.mConfig.editBook.bookId);
    } else {
      left = this.managerService.getBookChapter(this.mConfig.editBook.gradeId, this.mConfig.subjectId, this.mConfig.versionId);
    }
    let right;
    let link;
    const num = this.mConfig.isKnowledge || this.mConfig.rySystem ? 0 : -1;
    if (!this.mConfig.isKnowledge) {
      if (this.mConfig.rySystem) {
        // right = this.managerService.getRYKnowledgesBySubjectIdAndLevelId(this.mConfig.subjectId, this.mConfig.levelId);
        right = this.managerService.getRYKnowledge(this.mConfig.relId);
        link = this.managerService.getRYOutlineSection(this.mConfig.levelId,
          this.mConfig.subjectId, this.mConfig.editBook.gradeId, this.mConfig.versionId);
      } else {
        right = this.managerService.getOutlineKnowledge(this.mConfig.levelId, this.mConfig.subjectId);
        if (this.mConfig.zlSystem) {
          link = this.managerService.getzlOutlineSection(this.mConfig.relId);
        } else {
          link = this.managerService.getOutlineSection(this.mConfig.relId);
        }
      }
    } else {
      right = this.managerService.getknowledgesBySubjectIdAndLevelId(this.mConfig.subjectId, this.mConfig.levelId);
      link = this.managerService.getknowledges(_.parseInt(this.mConfig.relId));
    }
    zip(left, right, link)
      .subscribe(([lData, RData, linkData]) => {
          if (!this.mConfig.isKnowledge) {
            if (this.mConfig.rySystem) {
              RData = RData.map((item) => {
                const obj: any = {
                  isEditing: false,
                  isButtonShow: false,
                  isInputShow: false,
                  deleted: false,
                  id: item.id,
                  name: item.name,
                  parentId: item.pid,
                  relId: this.mConfig.relId,
                  sort: item.index
                };
                return obj;
              });
            } else {
              RData = RData.map((item) => {
                const obj: any = {
                  isEditing: false,
                  isButtonShow: false,
                  isInputShow: false,
                  createTime: item.createdTime,
                  deleted: false,
                  depth: item.ruanyunCourseId,
                  id: item.id,
                  lastUpdate: item.lastUpdatedTime,
                  name: item.name,
                  parentId: item.parentId,
                  relId: this.mConfig.relId,
                  sort: item.index
                };
                return obj;
              });
            }
          }
        // 根据关联表 将大纲知识对应到章节的module上。
        const tree = this.toTreeForBook(lData, -1)
        lData = this.toFlatForBook(tree, []);
        lData.forEach(ld => {
          if (this.mConfig.rySystem) {
            ld.knowledge = linkData
            .filter(linkD => linkD.chapterId === ld.id)
            .map(linkD => RData.find(r => r.id.toString() === linkD.vendorChapterId))
            .filter(k => k != null);
          } else {
            ld.knowledge = linkData
              .filter(linkD => linkD.sectionId === ld.id)
              .map(linkD => RData.find(r => r.id === (this.mConfig.isKnowledge ? linkD.knowledgePointId : linkD.outlineId)))
              .filter(k => k != null);
          }
          this.updateKnowledgeText(ld)
        });
        this.linkData = linkData;
        this.nodes = this.toTree(RData, num);
        this.items = lData;
        this.loadService.hide();
      },
        (err) => {
          this.loadService.hide()
          toastErrorLoad(this.toast);
          console.error(err.message)
        }
      )

  }
  /* 转树并排序 */
  toTreeForBook(list: BookChapterLinkResult[], parentId: number): BookChapterLinkResult[] {
    const tree: BookChapterLinkResult[] = []
    list.forEach(it => {
      if (it.parentId == null) {
        it.parentId = -1;
      }
      if (it.parentId === parentId) {
        it.children = this.toTreeForBook(list, it.id)
          .sort((a, b) => a.sort > b.sort ? 1 : (a.sort < b.sort ? -1 : 0));
        tree.push(it);
      }
    })
    return tree.sort((a, b) => a.sort > b.sort ? 1 : (a.sort < b.sort ? -1 : 0));
  }

  /* 树展开 */
  toFlatForBook(tree: BookChapterLinkResult[], data: BookChapterLinkResult[]): BookChapterLinkResult[] {
    tree.forEach(it => {
      data.push(it)
      if (it.children != null && it.children.length > 0) {
        this.toFlatForBook(it.children, data)
      }
    })
    return data;
  }

  /* 列表转树结构 */
  toTree(list: KnowledgeResult[], parentId: number): KnowledgeResult[] {
    const tree: KnowledgeResult[] = [];
    list.forEach(it => {
      if (it.parentId === parentId) {
        it.children = this.toTree(list, it.id);
        // 默认展开
        if (it.children.length > 0) {
          it.isExpanded = true
        }
        tree.push(it)
      }
    });
    return tree
  }

  getKnowledgeText(knowledge: KnowledgeResult[], def?: string): string {
    if (knowledge == null || knowledge.length === 0) {
      return def
    }
    let str = '';
    knowledge.forEach((k, index) => {
      if (index === 0) {
        str = isNullOrUndefined(k.name) ? '' : k.name
      } else {
        str += ('，' + (isNullOrUndefined(k.name) ? '' : k.name))
      }
    });
    return str
  }

  // 更新章节的知识点
  private updateKnowledgeText(b: BookChapterLinkResult) {
    b.knowledgeText = this.getKnowledgeText(b.knowledge)
  }

  // 添加关联
  private saveToSever(knowledge: KnowledgeResult) {
    this.loadService.show();
    if (this.mConfig.zlSystem) {
      this.managerService.updateZlRelate([{outlineId: knowledge.id, sectionId: this.editBCLR.id}]).subscribe(
        (res) => {
          this.loadService.hide();
        }
      )
    } else if (this.mConfig.rySystem) {
      this.managerService.updateRYRelate([{ryChapterId: knowledge.id, oedChapterId: this.editBCLR.id, deleted: false}]).subscribe(
        (res) => {
          this.loadService.hide();
        }
      )
    } else {
      const obj = this.mConfig.isKnowledge ? this.managerService
      .ralateSectionKnowledge(this.editBCLR.id, [knowledge]) : this.managerService
    .ralateSectionOutline(this.editBCLR.id, [knowledge]);
  obj.subscribe(
      (res) => {
        this.loadService.hide();
        this.linkData.push(res[0]);
      },
      (err) => {
        toastErrorLoad(this.toast);
        this.loadService.hide();
        this.editBCLR.knowledge = this.editBCLR.knowledge.filter(it => it.id !== knowledge.id)
        this.updateKnowledgeText(this.editBCLR)
        this.updateCheckBox()
      })
    }
  }

  // 删除关联
  private deleteFromSever(knowledge: KnowledgeResult) {
    this.loadService.show();
    if (this.mConfig.zlSystem) {
      this.managerService.batchDeleteRelate([{outlineId: knowledge.id, sectionId: this.editBCLR.id}]).subscribe(
        (res) => {
          this.loadService.hide();
        }
      )
    } else if (this.mConfig.rySystem) {
      this.managerService.updateRYRelate([{ryChapterId: knowledge.id, oedChapterId: this.editBCLR.id, deleted: true}]).subscribe(
        (res) => {
          this.loadService.hide();
        }
      )
    } else {
      const linkFind = this.linkData.find(it =>
        (this.mConfig.isKnowledge ? it.knowledgePointId : it.outlineId) === knowledge.id && it.sectionId === this.editBCLR.id);
      if (isNullOrUndefined(this.linkData)) {
        return
      }
      const obj = this.mConfig.isKnowledge ? this.managerService
        .deleteSectionKnowledge(linkFind.id, this.editBCLR.id, knowledge) : this.managerService
        .deleteSectionOutline(linkFind.id, this.editBCLR.id, knowledge);
      obj.subscribe(
          (res) => {
            this.loadService.hide();
            this.linkData = this.linkData.filter(it => it.id !== res[0].id);
          },
          (err) => {
            toastErrorLoad(this.toast);
            this.loadService.hide();
            this.editBCLR.knowledge.push(knowledge);
            this.updateKnowledgeText(this.editBCLR);
            this.updateCheckBox()
          })
    }
  }

  selectRyVersion(ryVersion) {
    this.selectedRyVersion = ryVersion;
    this.ryBooks = _.filter(this.ryCourseMappings, t => t.versionId === this.selectedRyVersion.versionId);
    this.selectedRyBook = this.ryBooks[0];
  }

  selectRyBook(ryBook) {
    this.selectedRyBook = ryBook;
  }

  onClickConfirm() {
    this.dialogService.showConfirmDialog('提示', '修改教材匹配会删除原先的章节匹配，确定吗?', async() => {
      await this.managerService.updateRyBookMapping(this.mConfig.relId, this.selectedRyBook.id).toPromise();
      this.submittedRyBook = this.selectedRyBook;
      this.getData();
    });
  }
}
