import {Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
// import {Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ManageService } from '../../../core/manage.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent, IConfirmConfig } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { LoadingService } from '../../../shared/full-loading/loading.service';
import * as _ from 'lodash';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit {
  @ViewChild('firstTitle') firstTitleField: ElementRef;
  @ViewChild('title') titleField: ElementRef;
  @Input() childData: OutlinesResult ;
  @Input() treeData: Array<OutlinesResult> = [];
  @Input() parentData: Array<OutlinesResult> = [];
  @Input() levelId: number;
  @Input() subjectId: number;
  @Input() readonly: boolean;
  @Input() forbitDelete: boolean;

  addTitle: string;
  currentTitle: string;
  currentSort: number;
  open = true;
  iConfig: IConfirmConfig = {};
  max = 4;
  isKnowledge: Boolean = false;

  constructor(
    private manageService: ManageService,
    private modalService: NgbModal,
    private load: LoadingService,
    private router: Router
  ) {
  }

  ngOnInit() {
    if (_.includes(this.router.url, 'knowledgeSystem/knowledge')) {
      this.isKnowledge = true;
    }
  }

  // 显示输入框和右侧按钮
  textClick($event: Event, title: OutlinesResult) {
    $event.stopPropagation();
    this.restStatus(this.treeData, true);
    if (!this.readonly) {
      title.isEditing = true;
    }
    title.isButtonShow = true;
    this.currentTitle = title.name;
  }
  // 重置所有节点编辑，按钮是否展示状态为false
  restStatus(arry: OutlinesResult[], bShow: boolean) {
    _.forEach(arry, item => {
      item.isEditing = false;
      item.isInputShow = false;
      if (bShow) {
        item.isButtonShow = false;
      }
      if (item.child) {
        return this.restStatus(item.child, bShow);
      }
    })
  }
  // 隐藏输入框,显示右侧按钮。
  itemClick($event: Event, title: OutlinesResult) {
    $event.stopPropagation();
    this.restStatus(this.treeData, true);
    title.isButtonShow = true;
  }
  // 向下移动章节
  downSection(parent: OutlinesResult[], item: OutlinesResult) {
    const idx = _.findIndex(parent, item);
    const next = parent[idx + 1];
    parent[idx] = next;
    parent[idx + 1] = item;
    const sort = item.sort;
    item.sort = next.sort;
    parent[idx].sort = sort;
    if (this.isKnowledge) {
      const arrData = [];
      _.forEach(parent, (p, i) => {
        arrData.push({id: p.id, name: p.name, index: i})
      });
      this.manageService.updateKnowledgeNode(arrData).subscribe();
      return;
    }
    this.manageService.updateNodeSort( [{id: item.id, sort: item.sort}, {id: next.id, sort: next.sort}])
      .subscribe(d => {});
  }
  // 向上移动章节
  upSection(parent: OutlinesResult[], item: OutlinesResult) {
    const idx = _.findIndex(parent, item);
    const pre = parent[idx - 1];
    parent[idx] = pre;
    parent[idx - 1] = item;
    const sort = item.sort;
    item.sort = pre.sort;
    parent[idx].sort = sort;
    if (this.isKnowledge) {
      const arrData = [];
      _.forEach(parent, (p, i) => {
        arrData.push({id: p.id, name: p.name, index: i})
      });
      this.manageService.updateKnowledgeNode(arrData).subscribe();
      return;
    }
    this.manageService.updateNodeSort( [{id: item.id, sort: item.sort}, {id: pre.id, sort: pre.sort}]).subscribe();
  }

  // 删除章节
  deleteSection(parent: OutlinesResult[], item: OutlinesResult) {
     const deleteModal = this.modalService.open(ConfirmDialogComponent, {centered: true, size: 'sm'});
     this.iConfig.title = '删除章节';
     this.iConfig.message = '确定删除此章节吗？';
     this.iConfig.yes = () => this.yesDelete(parent, item, deleteModal);
     deleteModal.componentInstance.config = this.iConfig
  }

  // 增加章节
  AddSection($event: Event, item: OutlinesResult) {
    $event.stopPropagation();
    item.isEditing = false;
    item.isInputShow = true;
    this.inputClick($event);
    setTimeout(() => {
      this.editSonName();
    });
    this.addTitle = '';
  }

  contentClick($event: Event) {
    $event.stopPropagation();
    this.restStatus(this.treeData, false);
  }

  inputClick($event: Event) {
    $event.stopPropagation();
  }
  // 更新章节
  update(item: OutlinesResult) {
    item.isEditing = false;
    if (this.currentTitle && this.currentTitle !== item.name) {
      item.name = this.currentTitle;
      if (this.isKnowledge) {
        this.manageService.updateKnowledgeNode([{id: item.id, name: item.name, index: item.sort}]).subscribe();
        return;
      }
      this.manageService.updateNode({name: item.name, sort: item.sort}, item.id).subscribe();
    }
  }
  // 新建章节
  creat($event: Event, item: OutlinesResult) {
    this.load.show();
    item.isInputShow = false;
    $event.stopPropagation();
    if (_.size(item.child)) {
      this.currentSort = item.child[item.child.length - 1].sort + 1;
    } else {
      this.currentSort = 1;
    }
    const depth = item.depth + 1;
    if (this.isKnowledge) {
      const addData = {
        name: this.addTitle,
        pid: item.id,
        levelId: this.levelId,
        subjectId: this.subjectId
      };
      if (this.addTitle) {
        this.manageService.addKnowledgeNode(addData).subscribe(i => {
          item.isInputShow = false;
          item.child = item.child || [];
          const obj: any = {
            depth: i.ryCourseId,
            child: [],
            deleted: false,
            id: i.id,
            isButtonShow: false,
            isEditing: false,
            isInputShow: false,
            name: i.name,
            parentId: i.pid,
            relId: item.relId,
            sort: i.index
          };
          item.child.push(obj);
          this.load.hide();
        }, (err) => {
          this.load.hide();
        })
      } else {
        this.load.hide();
      }
      return;
    }
    const data = {
      name: this.addTitle,
      depth: depth,
      parentId: item.id,
      sort: this.currentSort,
      levelId: this.levelId,
      subjectId: this.subjectId
    };
    if (this.addTitle) {
      this.manageService.addNode(data).subscribe(i => {
        item.isInputShow = false;
        item.child = item.child || [];
        item.child.push(i);
        this.load.hide();
      }, (err) => {
        this.load.hide();
      })
    } else {
      this.load.hide();
    }
  }
  // 一级标题input的获取焦点事件
  editName(): void {
    this.firstTitleField.nativeElement.focus();
  }
  // 二级标题input的获取焦点事件
  editSonName(): void {
    this.titleField.nativeElement.focus();
  }
  getFocus() {
    setTimeout(() => {
      this.editName();
    })
  }
  // 隐藏子标题
  closeSubtitle() {
    this.open = false;
  }
  // 展开子标题
  openSubtitle() {
    this.open = true;
  }
  yesDelete(parent: OutlinesResult[], item: OutlinesResult, deleteModal: NgbModalRef) {
    this.load.show();
    this.manageService.deleteNode(item.id)
      .subscribe(
        () => {
          _.remove(parent, i => i === item);
          this.load.hide();
          deleteModal.close();
        }, (err) => {
          this.load.hide();
          deleteModal.close();
        });
  }
}
