import {Component, OnInit, ElementRef, ViewChild, Input} from '@angular/core';
import { ManageService } from '../../../core/manage.service';
import { LoadingService } from '../../../shared/full-loading/loading.service';
import * as _ from 'lodash';
import {zip} from 'rxjs';
import {toastErrorLoad} from '../../../core/utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-knowledge-title',
  templateUrl: './knowledge-title.component.html',
  styleUrls: ['./knowledge-title.component.scss']
})
export class KnowledgeTitleComponent implements OnInit {
  @ViewChild('name') nameField: ElementRef;
  levelName: string;
  subjectName: string;
  levelId: number;
  subjectId: number;
  currentRelId: number;
  firstTitle: string;
  inputShow = false;
  parent: Array<OutlinesResult> = [];
  child: Array<OutlinesResult> = [];
  isKnowledge: Boolean = false;
  @Input() readonly: boolean;
  @Input() forbitDelete: boolean;
  constructor(
    private manageService: ManageService,
    private load: LoadingService,
    private router: Router
) {}
  ngOnInit() {
    if (_.includes(this.router.url, 'knowledgeSystem/knowledge')) {
      this.isKnowledge = true;
    }
    this.getData();
  }
  private getData() {
    this.load.show();
    // 获取到当前选中的学段，学科等信息
    this.manageService.getCurrentSelected().subscribe((res) => {
      this.levelName = res[0].levelName;
      this.levelId = res[0].levelId;
      this.subjectName = res[1].subjectName;
      this.subjectId = res[1].id;
      const data = [];
      const rel = res[2];
      if (_.size(rel) !== 0) {
        this.currentRelId = rel[0].relId;
        let resData;
        if (_.includes(this.router.url, 'knowledgeSystem/knowledge')) {
          resData = this.manageService.getRYKnowledgesBySubjectIdAndLevelId(this.subjectId, this.levelId);
        } else {
          resData = this.manageService.getknowledgesBySubjectIdAndLevelId(this.subjectId, this.levelId);
        }
        resData.subscribe(result => {
          _.forEach(result, (item: any) => {
            const obj: any = {
              isEditing: false,
              isButtonShow: false,
              isInputShow: false,
              deleted: false,
              depth: item.ryCourseId,
              id: item.id,
              name: item.name,
              parentId: item.pid,
              relId: this.currentRelId,
              sort: item.index
            };
            data.push(obj);
          });
          this.parent = this.getTree({}, 0, data);
          this.load.hide();
        })
      } else {
        this.parent = [];
        this.load.hide();
      }
      this.load.hide();
    });
  }
  // 将数据生成树状结构
  public getTree(root: any, id: number, nodes: OutlinesResult[]) {
    let child = _.filter(nodes, node =>  node.parentId === id);
    child = _.sortBy(child, 'sort');
    _.each(child, c =>  this.getTree(c, c.id, nodes));
    root.child = child;
    return root.child;
  }

  public addFistTitle($event: Event) {
    $event.stopPropagation();
    this.restStatus(this.parent);
    this.firstTitle = '';
    this.inputShow = true;
    this.inputClick($event);
    setTimeout(() => {
      this.editName();
    })
  }

  public hiddenInput() {
    this.inputShow = false;
  }

  public inputClick($event: Event) {
    $event.stopPropagation();
  }

  public create($event: Event) {
    $event.stopPropagation();
    this.inputShow = false;
    this.load.show();
    if (this.isKnowledge) {
      const addData = {
        name: this.firstTitle,
        pid: 0,
        levelId: this.levelId,
        subjectId: this.subjectId
      };
      if (this.firstTitle) {
        this.manageService.addKnowledgeNode(addData).subscribe(i => {
          const obj: any = {
            isEditing: false,
            isButtonShow: false,
            isInputShow: false,
            deleted: false,
            depth: i.ryCourseId,
            id: i.id,
            name: i.name,
            parentId: i.pid,
            relId: this.currentRelId,
            sort: i.index,
            child: []
          };
          this.parent.push(obj);
          this.load.hide();
        }, (err) => {
          this.load.hide();
        })
      } else {
        this.load.hide();
      }
      return;
    }
    const data = {
      name: this.firstTitle,
      depth: 1,
      parentId: -1,
      sort: 1,
      levelId: this.levelId,
      subjectId: this.subjectId
    };
    if (this.firstTitle) {
      this.manageService.addNode(data).subscribe(i => {
        this.parent.push(i);
        this.load.hide();
      }, (err) => {
        this.load.hide();
      })
    } else {
      this.load.hide();
    }
  }
  // 重置所有节点编辑，按钮是否展示状态为false
  public restStatus(arry: OutlinesResult[]) {
    _.forEach(arry, item => {
      item.isEditing = false;
      item.isInputShow = false;
      item.isButtonShow = false;
      if (item.child) {
        return this.restStatus(item.child);
      }
    })
  }

  // input的获取焦点事件
  public editName(): void {
    this.nameField.nativeElement.focus();
  }

}
