import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MarkPaperComponent } from './mark-paper.component'
import { MarkingTestComponent} from './marking-test/marking-test.component'
import {MarkPaperRoutingRoutes} from './mark-paper-routing.module'
import { FormsModule } from '@angular/forms'
import {MarkTitleBarComponent} from './mark-title-bar/mark-title-bar.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import {ReviewPapersComponent} from './reviewPapers/reviewPapers.component'
import { ReviewAnswerDialogComponent } from './review-answer-dialog/review-answer-dialog.component'
import { ReviewQuestionDialogComponent } from './review-question-dialog/review-question-dialog.component'
import {SharedModule} from '../shared/shared.module'
import {ReviewQuestionAnswerComponent} from './review-question-answer/review-question-answer.component';
import {MarkConfirmDialogComponent} from './mark-confirm-dialog/mark-confirm-dialog.component';
@NgModule({
  imports: [
    CommonModule,
    MarkPaperRoutingRoutes,
    FormsModule,
    NgbModule,
    SharedModule
  ],
  declarations: [MarkPaperComponent, MarkingTestComponent, MarkTitleBarComponent,
      ReviewPapersComponent, ReviewAnswerDialogComponent, ReviewQuestionDialogComponent,
      ReviewQuestionAnswerComponent],
  entryComponents: [ReviewAnswerDialogComponent, ReviewQuestionDialogComponent]
})
export class MarkPaperModule { }
