import { Component, OnInit } from '@angular/core';
import {NgbDateStruct, NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { ManageService } from '../../core/manage.service';
import {nextDay, toastErrorLoad} from 'src/app/core/utils';
import { ToastrService } from 'ngx-toastr';
import {LoadingService} from '../../shared/full-loading/loading.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-marking-test',
  templateUrl: './marking-test.component.html',
  styleUrls: ['./marking-test.component.scss']
})
export class MarkingTestComponent implements OnInit {
  subjectList: Array<SubjectDto> = [];
  curSelectSubject: SubjectDto;
  startDate: NgbDate;
  endDate: NgbDate;
  selectToDay: Boolean = false;
  quesList: Array<any> = [];
  page: any = 1;
  collectionSize: Number;
  pageSize: any = 10;
  totalNum: any; // 用Number不能进行计算
  constructor(private calendar: NgbCalendar,
              private managerService: ManageService,
              private toast: ToastrService,
              private load: LoadingService) {
  }

  ngOnInit() {
    this.managerService.getSubjectList().subscribe(
      (subList) => {
        this.subjectList = subList;
        for (let index = 0; index < this.subjectList.length; index++) {
          this.subjectList[index]['select'] = false;
        }
        this.curSelectSubject = {id: 0, sort: 0, subjectName: '全部', select: true};
        this.subjectList.unshift({id: 0, sort: 0, subjectName: '全部', select: true});
      }
    )
    this.endDate = this.calendar.getToday();
    this.startDate = this.calendar.getPrev(this.endDate, 'd', 13);
    this.getPaperData(0);
    this.loadCount(0, false)
  }

  loadCount (subjectId, isReload) {
    this.load.show();
    if (this.page !== 1) {
      this.page = 1;
    }
    const endDate = nextDay(new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`)).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    const archived = false;
    this.managerService.getReviewPaperTotalCount({endDate, startDate, subjectId, archived}).subscribe(
      (res) => {
        this.totalNum = res;
        this.collectionSize = Math.ceil(this.totalNum / this.pageSize) * 10;
        this.load.hide();
      }, () => this.load.hide()
    );
    if (isReload) {
      this.getPaperData(this.curSelectSubject.id)
    }
  }

  getPaperData(subjectId) {
    this.load.show()
    const endDate = nextDay(new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`)).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    const pageSize = this.pageSize;
    const startCount = (this.page - 1 ) * pageSize;
    const archived = false;
    this.managerService.getReviewPaperList({endDate, startDate, subjectId, startCount, pageSize, archived}).subscribe(
      async (res) => {
        const examSessionList = [];
        const examSessionIdList = [];
        _.forEach(res, t => {
          if (t.examSessionId) {
            examSessionIdList.push(t.examSessionId)
          }
        });
        for (const id of _.uniq(examSessionIdList)) {
          const examSession = await this.managerService.getExamSessionById(id).toPromise();
          examSessionList.push(examSession);
        }
        _.forEach(res, t => {
          t.examSession = _.find(examSessionList, es => es.id === t.examSessionId);
        });
        this.quesList = res;
        this.load.hide();
      },
      () => this.load.hide()
    )
  }

  selectSubject(item) {
    if (item.select) {
      return;
    }
    this.subjectList.filter((i: any) => i.select)[0].select = false;
    item.select = !item.select;
    this.curSelectSubject = item;
    this.selectData();
  }

  checkToday() {
    this.selectToDay = !this.selectToDay;
    if (this.selectToDay) {
      this.startDate = this.calendar.getToday();
      this.endDate = this.calendar.getToday();
    }
  }

  async selectData() {
    const endDate = new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    const dValue = endDate - startDate;
    if (dValue < 0) {
      return   toastErrorLoad(this.toast, '开始日期不能大于结束日期');
    }
    if ( (dValue / (1000 * 60 * 60 * 24) ) > 14) {
      return   toastErrorLoad(this.toast, '查询时间不能大于两周');
    }
    this.loadCount(this.curSelectSubject.id, true);
  }
  loadData () {
    this.getPaperData(this.curSelectSubject.id);
  }
  checkDayIsToday() {
    const toDay = this.calendar.getToday();
    const todayDate = new Date(`${toDay.year}-${toDay.month}-${toDay.day}`).getTime();
    const endDate = new Date(`${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`).getTime();
    const startDate = new Date(`${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`).getTime();
    if (todayDate ===  endDate && todayDate ===  startDate ) {
      this.selectToDay = true;
    } else {
      this.selectToDay = false;
    }
  }
}
