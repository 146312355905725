import { Component } from '@angular/core'
import { AppStateService } from './core/app-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rayEngine'

  constructor(private appStateService: AppStateService) {}
}
