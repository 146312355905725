import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash'
import { ManageService } from 'src/app/core/manage.service';
import { toastErrorLoad } from 'src/app/core/utils';
import { ToastrService } from 'ngx-toastr';
import {DialogService} from '../../shared/confirm-dialog/dialog.service';
@Component({
  selector: 'app-mark-table',
  templateUrl: './mark-table.component.html',
  styleUrls: ['./mark-table.component.scss']
})
export class MarkTableComponent implements OnInit, OnChanges {

  constructor(
    private route: Router,
    private managerService: ManageService,
    private toast: ToastrService,
    private dialogService: DialogService
  ) { }
  @Input() dataList: Array<any> = [];
  @Input() isProgress: boolean;
  @Input() archived: boolean;
  @Output() archivedPapers: EventEmitter<any> = new EventEmitter();
  @Output() cancelArchivedPapers: EventEmitter<any> = new EventEmitter();
  sortObject: any;
  customeThDataList: Array<ThData> = [];
  thNameArr = ['总人数', '已提交人数', '已阅', '最早提交时间'];
  thNameMap = {
    '总人数': 'count',
    '已提交人数': 'submitCount',
    '已阅': 'reviewCount',
    '最早提交时间': 'firstSubmitDate',
  }
  ngOnInit() {
    this.sortObject = {
      name: '',
      direction: ''
    }
    this.pushThData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.sortObject) {
      this.sort();
    }
  }

  pushThData() {
    for (let index = 0; index < this.thNameArr.length; index++) {
      this.customeThDataList.push({name: this.thNameArr[index], direction: ''});
    }
  }
  clickSort(data: ThData) {
    data.direction = data.direction === 'asc' ? 'desc' : 'asc'
    this.sortObject = data;
    this.sort();
    // if (data.name === this.sortObject.name) {
    //   if (data.direction === 'asc') {
    //     this.sortObject.direction = 'desc';
    //     data.direction = 'desc';
    //   } else {
    //     this.sortObject.direction = 'asc';
    //     data.direction = 'asc';
    //   }
    //   this.dataList = this.dataList.sort( (a , b) => {
    //     const tempkey = this.thNameMap[this.sortObject.name];
    //     return  data.direction === 'asc' ? a[tempkey] - b[tempkey] : b[tempkey] - a[tempkey];
    //   })
    // } else {
    //   this.sortObject.name = data.name;
    //   this.sortObject.direction = 'desc';
    //   data.direction = 'desc';
    //   this.dataList = this.dataList.sort( (a , b) => {
    //     const tempkey = this.thNameMap[this.sortObject.name];
    //     if (!b[tempkey]) {
    //       return -1;
    //     }
    //     if (!a[tempkey]) {
    //       return 1;
    //     }
    //     return  b[tempkey] - a[tempkey];
    //   })
    // }
  }
  sort() {
    this.dataList = this.dataList.sort( (a , b) => {
      const tempkey = this.thNameMap[this.sortObject.name];
      if (b[tempkey] == null) {
        return -1;
      }
      if (a[tempkey] == null) {
        return 1;
      }
      return  this.sortObject.direction === 'asc' ? a[tempkey] - b[tempkey] : b[tempkey] - a[tempkey];
    })
  }
  reviewPapers(selectItem) {
    const type = selectItem.previewType;
    if (this.isProgress) {
      if ( type ===  '考试') {
        this.route.navigate(['mark', 'reviewPaper'],
          { queryParams: {questionId: selectItem.questionId, examSessionId: selectItem.examSessionId,
              questionSort: selectItem.sort, readOnly: true}});
      } else {
        this.route.navigate(['mark', 'reviewPaper'],
          { queryParams: {questionId: selectItem.questionId, testSessionId: selectItem.testSessionId,
              questionSort: selectItem.sort, readOnly: true}});
      }
      return;
    }
    const query = {
      'reviewType': type === '考试' ? 1 : 0,
      'sessionId': type === '考试' ? selectItem.examSessionId : selectItem.testSessionId,
      'questionId': selectItem.questionId,
      'studentId': 0,
      'point': 0,
    };
    this.managerService.getQuestStatus(query).subscribe(
      async (res) => {
        if (res) {
          if ( type ===  '考试') {
            const examSession = await this.managerService.getExamSessionById(selectItem.examSessionId).toPromise()
            const readOnly = examSession.status === 'reviewed';
            this.route.navigate(['mark', 'reviewPaper'],
            { queryParams: {questionId: selectItem.questionId,
                examSessionId:  selectItem.examSessionId, questionSort: selectItem.sort, readOnly}});
          } else {
            this.route.navigate(['mark', 'reviewPaper'],
            { queryParams: {questionId: selectItem.questionId, testSessionId:  selectItem.testSessionId, questionSort: selectItem.sort}});
          }
        } else {
           return   toastErrorLoad(this.toast, '当前题目有其他阅卷人员正在批改');
        }
      }
    )
  }
  archivedPapersFun(selectItem) {
    this.archivedPapers.emit(selectItem);
  }
  cancelArchivedPapersFun(selectItem) {
    this.cancelArchivedPapers.emit(selectItem);
  }

  showEndExam(item) {
     return item.examSessionId && item.examSession && _.get(item.examSession, 'status') !== 'reviewed' && item.showEndExam === true;
  }

  hoverTitle(item) {
    item.showEndExam = true;
  }

  hoverOutOfTitle(item) {
    item.showEndExam = false;
  }

  endExam(examSessionId) {
    this.dialogService.showConfirmDialog( '结束阅卷', '本次考试其他未阅题目将不能阅卷，是否结束?', () => {
      this.managerService.endExamSessionReview(examSessionId).subscribe(r => {
        this.dataList.forEach(d => {
          if (d.examSessionId === examSessionId) {
            d.examSession.status = 'reviewed';
          }
        })
      });
    })
  }
}
