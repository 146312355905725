import {Component, OnInit} from '@angular/core';
import {MenuService} from '../../core/menu.service';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss']
})
export class SideNavBarComponent implements OnInit {

  constructor(
    private menuService: MenuService
  ) {

  }

  menus: MenuDTO[];

  ngOnInit() {
    this.menus = this.menuService.getMenu();
  }

}
