import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppStateService } from '../core/app-state.service';
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private appState: AppStateService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.appState.isReady()) {
      return this.validateUser(state.url)
    }
    /**
     * app 启动时, 如果appState 还没有ready, 等待userInfo加载后再判断时候有权限
     */
    return this.appState.userDataSubject.toPromise().then(data => {
      return this.validateUser(state.url)
    })
  }

  private validateUser(url: string): boolean {
    const isLogin = url === '/pre/login'
    const needAuth = !_.startsWith(url, '/pre')
    const hasUser = this.appState.userData != null

    if (isLogin) {
      if (hasUser) {
        this.router.navigate(['man', 'knowledge'])
        return false
      }
      return true
    }

    if (!needAuth) {
      return true
    }

    if (hasUser) {
      return this.checkUserRoles(this.appState.userData)
    }
    this.router.navigate(['/pre', 'login'])
    return false
  }

  private checkUserRoles(user: UserData) {
    /*
      暂时禁用
      if (!_.has(user.roles, 'admin')) {
      this.router.navigate(['/pre', 'denied'])
      return false
      } */
    return true
  }
}
