import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfoService } from 'src/app/core/user-info.service';
import { finalize } from 'rxjs/operators';
import {AppStateService} from '../../core/app-state.service';
@Component({
  selector: 'app-mark-title-bar',
  templateUrl: './mark-title-bar.component.html',
  styleUrls: ['./mark-title-bar.component.scss']
})
export class MarkTitleBarComponent implements OnInit {

  constructor(private route: Router, private userInfoService: UserInfoService) { }
  name: String;
  @Input() needBackBtn: Boolean;
  @Input() needLoginInfo: Boolean = true;
  ngOnInit() {
    const loginUserInfo = localStorage.getItem('mark_loginInfo');
    this.name = JSON.parse(loginUserInfo).name;
  }
  signOut() {
    this.userInfoService
    .logout()
    .pipe(finalize(() => {
      this.route.navigate(['/mark', 'login'])
    })).subscribe();
  }
  back() {
    history.back()
  }
}
