import { Injectable } from '@angular/core';
import {MarkConfirmDialogComponent} from '../../mark-paper/mark-confirm-dialog/mark-confirm-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private ngbModal: NgbModal) {

  }

  public showConfirmDialog(title, message, todo) {
    const confirmModal = this.ngbModal.open(MarkConfirmDialogComponent, {centered: true, size: 'sm', backdrop: 'static'});
    confirmModal.componentInstance.config = {
      title,
      message,
      yes: () => todo()
    }
  }
}
