import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-knowledge-outline',
  templateUrl: './knowledge-outline.component.html',
  styleUrls: ['./knowledge-outline.component.scss']
})
export class KnowledgeOutlineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
