import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PreRoutingModule } from './pre-routing.module';
import { LoginComponent } from './login/login.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [LoginComponent, AccessDeniedComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    PreRoutingModule
  ]
})
export class PreModule { }
