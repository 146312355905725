import { Routes, RouterModule } from '@angular/router';
import {MarkPaperComponent} from './mark-paper.component';
import {MarkingTestComponent} from './marking-test/marking-test.component'
import {ReviewPapersComponent} from './reviewPapers/reviewPapers.component'
const routes: Routes = [
  {
    path: 'login',
    component: MarkPaperComponent,
  }, {
    path: 'markingTest',
    component: MarkingTestComponent
  }, {
    path: 'reviewPaper',
    component: ReviewPapersComponent
  }
];

export const MarkPaperRoutingRoutes = RouterModule.forChild(routes);
