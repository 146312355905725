import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { httpInterceptorProviders } from './http-interceptors'
import { HttpClientModule } from '@angular/common/http'
import { TreeModule } from 'angular-tree-component';
import { FullLoadingComponent } from './shared/full-loading/full-loading.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    CoreModule,
    AppRoutingModule,
    TreeModule.forRoot(),
    FormsModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),
  ],
  providers: [
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
  entryComponents: [FullLoadingComponent],
})
export class AppModule {
}
