import {Component, OnInit} from '@angular/core';
import {ManageService} from '../../core/manage.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
import {ActivatedRoute, Router} from '@angular/router';
import {zip} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReviewAnswerDialogComponent, ReviewDialogConfig} from '../review-answer-dialog/review-answer-dialog.component';
import {ReviewQuestionDialogComponent} from '../review-question-dialog/review-question-dialog.component'
import {QuestionService} from '../../core/question.service';
import {MarkConfirmDialogComponent} from '../mark-confirm-dialog/mark-confirm-dialog.component';
import {toastErrorLoad} from '../../core/utils';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-review-papers',
  templateUrl: './reviewPapers.component.html',
  styleUrls: ['./reviewPapers.component.scss']
})
export class ReviewPapersComponent implements OnInit {
  studentAnswerList: Array<any> = [];
  curIndex: any = 0; // 阅卷进度的index
  curContentIndex = 0; // 左侧答案的index
  questionId: number;
  testSessionId: number;
  examSessionId: number;
  reviewType: any;
  curQuestion: any;
  iConfig: ReviewDialogConfig = {};
  quesConfig: any = {};
  readOnly: Boolean;
  questionSort: Number;
  constructor( private managerService: ManageService,
               private route: Router,
               private questionService: QuestionService,
               private activateInfo: ActivatedRoute,
               private modalService: NgbModal,
               private toastrService: ToastrService) {
    　activateInfo.queryParams.subscribe(queryParams => {
      this.questionId = queryParams.questionId;
      this.testSessionId = Number(queryParams.testSessionId);
      this.examSessionId = Number(queryParams.examSessionId);
      this.readOnly = queryParams.readOnly === 'true';
      this.questionSort = queryParams.questionSort;
    })
   }

  ngOnInit() {
    this.getStuAnswerList();
    this.reviewType = this.testSessionId ? 0 : 1;
  }
  getStuAnswerList() {
    zip( this.testSessionId ? this.managerService.getStuAnswerList({questionId: this.questionId, testSessionId: this.testSessionId}) :
    this.managerService.getStuAnswerListByExam({questionId: this.questionId, sessionId: this.examSessionId}),
    this.managerService.getQuestionInfo(this.questionId))
    .subscribe(([answers, question]) => {
      if (this.reviewType === 1) {
        this.handleRyKnowledgePoints(question);
      }
      this.curQuestion = question;
      this.handleAnswerListData(question, answers);
      // setTimeout(() => {
      //   $('.input_0').focus();
      //  }, 0);
    });
  }

  getQuestionByQId(question, qId) {
    if (question.id === qId) {
      return question;
    }
    return _.find(question.childQuestions, (item) => item.id === qId);
  }

  async handleRyKnowledgePoints(question) {
    const questionTags: QuestionTag[] = await this.managerService.getQuestionTagsByQuestionId(question.id).toPromise();
    question.ryKnowledgePoints = [];
    const ryKnowledgePointIds = [];
    for (const t of questionTags) {
      if (t.tagName === 'ruanyun.KnowledgePointId') {
        ryKnowledgePointIds.push(t.numVal);
      }
    }
    const knowledgeResults: KnowledgeResult[] = await this.managerService.getKnowledgePointsById(ryKnowledgePointIds).toPromise();
    question.ryKnowledgePoints = _.concat(question.ryKnowledgePoints, knowledgeResults);
  }

  handleAnswerListData(question, answers) {
    this.studentAnswerList = _(answers)
      .groupBy(t => t.studentId)
      .map((v, k) => {
        const qaList = [];
        _(v).groupBy('questionId')
          .forEach((ans, qId) => {
            const an = _.maxBy(ans, 'submitTime');
            const ques = this.getQuestionByQId(question, _.parseInt(qId));
            // 过滤掉其他题型
            if (ques.type !== 'qa' && ques.type !== 'fill') {
              return;
            }
            // 填空题只保留手动评判的
            if (ques.type === 'fill') {
              if (!_.includes(ques.answer, 'manual-score')) {
                return;
              }
            }
            if (this.asQa(ques)) {
              an.customePoint = (an.correctDetails === '-1' ? ques.point2 : (an.point2 || an.point)).toFixed(1);
            }
            if ( !_.isEmpty(an.answer)) {
              an.answerArr = JSON.parse(an.answer)
            }
            if ( !_.isEmpty(ques.answer)) {
              ques.answerObj = JSON.parse(ques.answer)
            }
            an.correctDetailsArr = _.split(an.correctDetails, ',');
            qaList.push({
                questionId: _.parseInt(qId),
                question: ques,
                answer: an,
              });
          });
        const contentList = [];
        qaList.forEach(qa => {
          if (!qa.question.sort) {
            qa.question.sort = 0;
          }
          if (qa.answer.answerArr.answer) {
            let i = 0;
            contentList.push({
              type: 'fill',
              question: qa.question,
              html: qa.question.question.replace(/_{2,}/g, () => {
                const fillContent = this.questionService.isEmpty(qa.answer.answerArr.answer[i]) ?
                  '<span>____</span><span class="fo-x-circle"></span>'
                  : '<span class="fill-content">' + qa.answer.answerArr.answer[i] + '</span>'
                i++;
                return fillContent;
              })
            })
          } else if (qa.answer.answerArr.items) {
            qa.answer.answerArr.items.forEach(item => {
              if (item.fileType === 'image') {
                contentList.push({
                  type: 'image',
                  item: item
                })
              }
            })
          }
        });
        return {studentId: _.parseInt(k), qaList, contentList}
      })
      .value();


  }
  isReviewed(sa) {
     return !_.some(_.get(sa, 'qaList'), (qa => {
       if (this.asFill(qa.question)) {
         return qa.answer.correctDetailsArr.includes ('-1');
       } else if (this.asQa(qa.question)) {
         return qa.answer.correctDetails === '-1';
       }
     }))
  }
  allReviewed() {
    return _.every(this.studentAnswerList, sa => this.isReviewed(sa));
  }
  keyUpSearch(inputIndex) {
    const nextIndex = this.studentAnswerList[this.curIndex].qaList.findIndex((qa, i) => this.asQa(qa.question) && i > inputIndex);
    if (nextIndex !== -1) {
      $('.input_' + nextIndex).focus();
    }
  }
  reviewAnswer() {
    const reviewAnswerModal = this.modalService.open(ReviewAnswerDialogComponent,
       {centered: true, windowClass: 'reviewAnswerDialog', backdrop: 'static'});
    this.iConfig.title = '答案解析';
    if (this.curQuestion.type === 'synthetic') {
      this.iConfig.data = this.curQuestion;
    } else {
      this.iConfig.message = this.curQuestion.answerDetails ? this.curQuestion.answerDetails : '暂无解析';
    }
    this.iConfig.type = this.curQuestion.type;
    this.iConfig.yesText = '确定';
    reviewAnswerModal.componentInstance.config = this.iConfig;
    this.iConfig.yes = () => reviewAnswerModal.close();
    this.iConfig.no = () => reviewAnswerModal.close();
  }
  reviewQues() {
    const reviewQuesModal = this.modalService.open(ReviewQuestionDialogComponent,
      {centered: true, windowClass: 'reviewQuesDialog', backdrop: 'static'});
    this.quesConfig.data = this.curQuestion;
    this.quesConfig.reviewType = this.reviewType;
    this.quesConfig.questionSort = this.questionSort
    reviewQuesModal.componentInstance.config = this.quesConfig;
    this.quesConfig.no = () => reviewQuesModal.close();
  }
  inputBlur(item) {
    item.answer.correctDetails = -2
  }
  inputChange(item) {
    item.answer.correctDetails = -2
    let cloneText = _.cloneDeep(item.answer.customePoint.replace(/[^\d.]/g, ''));
    cloneText = cloneText.replace(/^\./g, '0.'); // 验证第一个字符是数字
    cloneText = cloneText.replace(/\.{2,}/g, '.'); // 只保留第一个., 清除多余的
    cloneText = cloneText.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    cloneText = cloneText.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3'); // 只能输入两个小数
    const maxPoint = item.question.point2;
    // if (cloneText.lastIndexOf('.') !== -1 && cloneText.lastIndexOf('.') === cloneText.length - 2 && !cloneText.endsWith('0')) {
    //   cloneText = cloneText.substring(0, cloneText.length - 1) + '5';
    // }
    if (_.toNumber(cloneText) > maxPoint) {
      cloneText = maxPoint;
    }
    setTimeout(() => {
      item.answer.customePoint = cloneText;
    })
  }
  confirmPrevious(toLast) {
    if (this.hasPrevious()) {
      this.confirmReviewed(() => this.previous(toLast));
    }
  }
  previous(toLast) {
    if (this.hasPrevious()) {
      if (!this.readOnly) {
        this.testAnswerReview();
      }
      this.curIndex --;
      if (toLast) {
        this.curContentIndex = this.studentAnswerList[this.curIndex].contentList.length - 1;
      } else {
        this.curContentIndex = 0;
      }
    }
  }
  hasPrevious() {
    return this.curIndex > 0;
  }
  confirmNext() {
    if (this.hasNext()) {
      this.confirmReviewed(() => this.next());
    }
  }
  next() {
    if (this.hasNext()) {
      if (!this.readOnly) {
        this.testAnswerReview();
      }
      this.curIndex ++;
      this.curContentIndex = 0;
    }
  }
  hasNext() {
    return this.curIndex < this.studentAnswerList.length - 1;
  }
  confirmEnd() {
    this.confirmReviewed(() => this.end())
  }
  end() {
    if (!this.readOnly) {
      this.testAnswerReview();
      // 更新考试统计
      // if (this.reviewType === 1) {
      //   this.managerService.endExamSessionReview(this.examSessionId).subscribe();
      // }
    }
    this.back();
  }
  back() {
    this.route.navigate(['/mark', 'markingTest']);
  }
  confirmReviewed(fun) {
    if (!this.isReviewed(this.studentAnswerList[this.curIndex]) && !this.readOnly) {
      const confirmModal = this.modalService.open(MarkConfirmDialogComponent,
        {centered: true, size: 'sm', backdrop: 'static'});
      confirmModal.componentInstance.config = {
        title: '提示',
        message: '有未批的题目，请先批阅。',
        hideNoButton: true,
        yesText: '确定'
      }
    } else {
      fun();
    }
  }
  buttonPressed(result, i, j) {
    this.studentAnswerList[this.curIndex].qaList[i].answer.correctDetailsArr[j] = result ? '1' : '0';
  }
  async testAnswerReview() {
    const queryList = [];
    this.studentAnswerList[this.curIndex].qaList.forEach((t) => {
      if (this.asQa(t.question)) {
        if (_.isEmpty(t.answer.customePoint)) {
          return;
        }
        queryList.push({
          studentId: this.studentAnswerList[this.curIndex].studentId,
          sessionId: this.testSessionId ? this.testSessionId : this.examSessionId,
          questionId: t.question.id,
          point: _.toNumber(t.answer.customePoint),
          reviewType: this.reviewType,
        });
      } else if (this.asFill(t.question)) {
        t.question.answerObj.answerTypes.forEach((type, index) => {
          // 该空答案为空或者没有上传的答案则不批阅
          if (this.isItemEmpty(t, index)) {
            return;
          }
          if (type === 'manual-score' &&  t.answer.correctDetailsArr[index] !== '-1') {
            queryList.push({
              studentId: this.studentAnswerList[this.curIndex].studentId,
              sessionId: this.testSessionId ? this.testSessionId : this.examSessionId,
              questionId: t.question.id,
              blankId: index,
              point: t.answer.correctDetailsArr[index] === '1' ? 1 : 0, // 此处1代表正确,0代表错误,并不是指分数
              reviewType: this.reviewType,
            });
          }
        })
      }
    })
    for (const query of queryList) {
      try {
        await this.managerService.testAnswerReview(query).toPromise();
      } catch (e) {
        if (e.error.message === 'testAnswerReview/cannotReview') {
          toastErrorLoad(this.toastrService, '当前题目有其他阅卷人员正在批改，批阅失败');
          this.back()
          break;
        }
      }
    }
  }
  isItemEmpty(item, j) {
    return !(item.answer.answerArr.answer && !this.questionService.isEmpty(item.answer.answerArr.answer[j]));
    // if ((item.answer.answerArr.items && item.answer.answerArr.items.length !== 0)) {
    //   return false;
    // }
  }
  getFillTotalScore(item, j) {
    if (item.answer.correctDetailsArr[j] !== '1') {
      return 0;
    } else if (item.question.answerObj.answerPoints2) {
      return item.question.answerObj.answerPoints2[j];
    } else if (item.question.answerObj.answerPoints) {
      return item.question.answerObj.answerPoints[j];
    } else {
      return 0;
    }
  }

  /**
   * 判断一道题是否要以问答题的方式处理,考试的填空按问答题处理
   */
  asQa(question) {
    return question.type === 'qa' || this.reviewType === 1
  }

  asFill(question) {
    return question.type === 'fill' && this.reviewType === 0
  }
  nextContent() {
    this.curContentIndex++;
  }
  previousContent() {
    this.curContentIndex--;
  }
}
