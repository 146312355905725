import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorrectComponent } from './correct.component';
import { CorrecrRoutingRoutes } from './correct-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AccountManageComponent } from './account-manage/account-manage.component';
import { MarkingProgressComponent } from './marking-progress/marking-progress.component';
import { PersonnelListComponent } from './personnel-list/personnel-list.component';
import { SchoolManageComponent } from './school-manage/school-manage.component';
import {AddOrEditSchoolComponent} from './school-manage/add-or-edit-school/add-or-edit-school.component';
import {AddOrEditAccountComponent} from './account-manage/add-or-edit-account/add-or-edit-account.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    CorrecrRoutingRoutes,
    SharedModule,
    NgbModule,
    FormsModule,
  ],
  declarations: [
    CorrectComponent,
    AccountManageComponent,
    MarkingProgressComponent,
    PersonnelListComponent,
    SchoolManageComponent,
    AddOrEditSchoolComponent,
    AddOrEditAccountComponent,
  ],
  entryComponents: [
    AddOrEditSchoolComponent,
    AddOrEditAccountComponent
  ]
})
export class CorrectModule { }
