import { Component, OnInit } from '@angular/core';
import { Config } from '../general/level-to-version/level-to-version.component';
import { ManageService } from '../../core/manage.service';
import { zip } from 'rxjs';
import * as _ from 'lodash'
import { isNullOrUndefined } from 'util';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BookLinkKnowledgeComponent } from './book-link-knowledge/book-link-knowledge.component';
import { ConfirmDialogComponent, IConfirmConfig } from '../../shared/confirm-dialog/confirm-dialog.component';
import { LoadingService } from '../../shared/full-loading/loading.service';
import { ToastrService } from 'ngx-toastr';
import { toastErrorLoad } from '../../core/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-knowledge-textbook',
  templateUrl: './knowledge-textbook.component.html',
  styleUrls: ['./knowledge-textbook.component.scss']
})
export class KnowledgeTextbookComponent implements OnInit, Config, BookLinkConfig {

  needConfig: Config;
  private allBooks: Array<BookResult>; // 所有教材
  private lgvs: Array<LgvsResult>; // 对应关系
  private currentLgvs: Array<LgvsResult>; // 对应关系
  books: Array<BookResult>; // 当前显示的教材
  // BookLinkConfig
  editBook: BookResult; //
  levelId: number
  subjectId: number;
  versionId: number;
  relId: string;
  outlineTitle: string;
  isKnowledge: any = false;

  iConfig: IConfirmConfig = {};
  zlSystem: Boolean = false;
  rySystem: Boolean = false;
  constructor(
    private managerService: ManageService,
    private modalService: NgbModal,
    private load: LoadingService,
    private toast: ToastrService,
    private router: Router
  ) {}


  ngOnInit() {
    this.needConfig = this;
    if (_.includes(this.router.url, 'knowledgeRelation')) {
      this.isKnowledge = true;
    }
    if (_.includes(this.router.url, 'zlTextbook')) {
      this.zlSystem = true;
    }
    if (_.includes(this.router.url, 'ryTextbook')) {
      this.rySystem = true;
    }
    this.getData()
  }

  private getData() {
    this.load.show();
    if (this.zlSystem) {
      this.managerService.getZlInfo().subscribe((res) => {
        this.load.hide();
        this.allBooks = res.map(item => {
          return {id: item.id, levelId: item.vendorLevelId, subjectId: item.vendorSubjectId,
            versionId: item.vendorVersionId, bookId: item.vendorBookId, gradeName: item.vendorBookName, gradeId: item.gradeId};
        });
        this.lgvs = res.map(item => {
          return {gradeId: item.gradeId, levelId: item.vendorLevelId, relId: item.lgvsId,
            subjectId: item.vendorSubjectId , versionId: item.vendorVersionId}
        });
        this.reloadBooks();
      })
    // } else if (this.rySystem) {
    //   this.managerService.getRanyunBooks('ruanyun').subscribe((res) => {
    //     this.load.hide();
    //     this.allBooks = res.map(item => {
    //       return {id: item.id, levelId: item.levelId, subjectId: item.subjectId, relId: item.lgvsId,
    //         versionId: item.versionId, bookId: item.vendorGradeBookId, gradeName: item.vendorGradeBookName, gradeId: item.gradeId};
    //     });
    //     this.lgvs = res.map(item => {
    //       return {gradeId: item.gradeId, levelId: item.levelId, relId: item.lgvsId,
    //         subjectId: item.subjectId , versionId: item.versionId}
    //     });
    //     this.reloadBooks();
    //   });
    } else {
      zip(this.managerService.getAllBooks(), this.managerService.getLgvs())
      .subscribe(([bk, lgv]) => {
        this.allBooks = bk;
        this.lgvs = lgv;
        this.reloadBooks();
        this.load.hide()
      }, (err) => {
        this.load.hide();
        toastErrorLoad(this.toast);
        console.error('knowledge-textbook.getData', err.message)
      });
    }
  }

  reloadBooks() {
    if (this.zlSystem) {
      if (
        isNullOrUndefined(this.needConfig.selectLevelItem) ||
        isNullOrUndefined(this.needConfig.selectSubjectItem) ||
        isNullOrUndefined(this.needConfig.selectVersionItem) ||  isNullOrUndefined(this.allBooks)) {
        return
      }
      this.books = _.uniqBy(this.allBooks.filter(lg =>
        lg.levelId === this.needConfig.selectLevelItem.levelId &&
        lg.subjectId === this.needConfig.selectSubjectItem.id &&
        lg.versionId === this.needConfig.selectVersionItem.versionId
      ), (lg) => {
        return lg.gradeId
      });
      if (this.rySystem) {
        this.currentLgvs = _.uniqBy(this.lgvs.filter(lg =>
          lg.levelId === this.needConfig.selectLevelItem.levelId &&
          lg.subjectId === this.needConfig.selectSubjectItem.id &&
          lg.versionId === this.needConfig.selectVersionItem.versionId
        ), (lg) => {
          return lg.gradeId
        });
      }
    } else {
      if (
        isNullOrUndefined(this.needConfig.selectLevelItem) ||
        isNullOrUndefined(this.needConfig.selectSubjectItem) ||
        isNullOrUndefined(this.needConfig.selectVersionItem) ||
        isNullOrUndefined(this.lgvs)) {
        return
      }
      this.currentLgvs = _.uniqBy(this.lgvs.filter(lg =>
        lg.levelId === this.needConfig.selectLevelItem.levelId &&
        lg.subjectId === this.needConfig.selectSubjectItem.id &&
        lg.versionId === this.needConfig.selectVersionItem.versionId
      ), (lg) => {
        return lg.gradeId
      });
    }

    this.getLinkStatus();
  }

  onChange() {
    this.reloadBooks()
  }

  // 打开编辑关联
  onClickItem(book: BookResult) {
    if (this.zlSystem) {
      this.levelId = this.needConfig.selectLevelItem.oedlevelId;
      this.subjectId = this.needConfig.selectSubjectItem.oedSubjectId;
      this.versionId = this.needConfig.selectVersionItem.oedVersionId;
      this.relId = book.id;
    } else {
      this.levelId = this.needConfig.selectLevelItem.levelId;
      this.subjectId = this.needConfig.selectSubjectItem.id;
      this.versionId = this.needConfig.selectVersionItem.versionId;
      this.relId = this.findRelId(book);
    }
    this.outlineTitle = book.gradeName;
    // this.outlineTitle = this.needConfig.selectLevelItem.levelName + this.needConfig.selectSubjectItem.subjectName;
    this.editBook = book;
    const modalRef = this.modalService
      .open(BookLinkKnowledgeComponent, {centered: true, size: 'lg', windowClass: 'modal-dialog-big'});
    modalRef.componentInstance.mConfig = this
    modalRef.result
      .then(() => this.getLinkStatus())
      .catch( () => this.getLinkStatus());
  }

  // 点击删除
  onClickDelete(book: BookResult) {
    event.stopPropagation();
    const relIdFind = this.findRel(book);
    if (relIdFind == null) {
      return
    }
    this.load.show();
    this.managerService.getCoursePathRel(relIdFind.relId)
      .subscribe((res) => {
        this.load.hide();
        let message = '';
        if (res.length === 0) {
          message = '当前教材尚未被使用，确定删除吗?'
        } else {
          message = '以下课程属于本教材，删除教材之后，课程将被自动关联到"其他"教材，确定删除吗?';
          res.forEach(it => message += ('<br>' + it.name))
        }
        const deleteModal = this.modalService.open(ConfirmDialogComponent, {centered: true, size: 'sm'});
        this.iConfig.title = '删除教材';
        this.iConfig.message = message;
        this.iConfig.yes = () => this.yesDelete(relIdFind, deleteModal);
        deleteModal.componentInstance.config = this.iConfig
      })
  }


  findRelId(book: BookResult): string {
    const find = this.findRel(book)
    if (isNullOrUndefined(find)) {
      return null;
    }
    return find.relId;
  }

  findRel(book: BookResult): LgvsResult {
    return this.lgvs.find(
      f =>
        f.levelId === this.needConfig.selectLevelItem.levelId &&
        f.subjectId === this.needConfig.selectSubjectItem.id &&
        f.versionId === this.needConfig.selectVersionItem.versionId &&
        f.gradeId === book.gradeId
    )
  }

  yesDelete(rel: LgvsResult, deleteModal: NgbModalRef) {
    this.load.show(false);
    this.managerService.deleteBook(rel.relId)
      .subscribe(
        (res) => {
          this.load.hide()
          deleteModal.close()
          this.lgvs = this.lgvs.filter(it => it.relId !== rel.relId)
          this.books = this.books.filter(it => it.gradeId !== rel.gradeId)
        },
        (err) => {
          this.load.hide()
          deleteModal.close()
        })
  }

  // 获取状态
  private getLinkStatus() {
    if (this.zlSystem) {
      this.load.show(false)
      this.managerService.getzlBookLinkStatus(this.books.map(bk => bk.id)).subscribe(
        (bls) => {
          this.load.hide()
          _.each(this.books, item => {
            item.status = bls.find((lr) => lr.id === item.id) ? true : false;
          })
        }
      )
    // } else if (this.rySystem) {
    //   this.managerService.getRYBookLinkStatus(this.currentLgvs.map(lg => '&ids=' + lg.relId).join('')).subscribe(
    //     (bls) => {
    //       _.each(this.books, item => {
    //         item.status = bls.find((lr) => lr.relId === item.relId).status;
    //       })
    //       this.load.hide()
    //     }
    //   )
    } else {
      if (this.currentLgvs == null) {
        return
      }
      this.load.show(false)
      if (!this.isKnowledge) {
        this.managerService.getBookLinkStatus(this.currentLgvs.map(lg => lg.relId))
          .subscribe(
            (bls) => {
              this.books = this.currentLgvs
                .map((lg) => {
                  const book = this.allBooks.find((b) => lg.gradeId === b.gradeId);
                  book.status = bls.find((lr) => lr.relId === lg.relId).status;
                  return book
                });
              this.books = _.sortBy(this.books, b => b.sort);
              this.load.hide()
            },
            (err) => {
              toastErrorLoad(this.toast)
              this.load.hide()
            });
      } else {
        this.books = [];
          _.map(this.currentLgvs, (lg) => {
            this.managerService.getknowledges(_.parseInt(lg.relId)).subscribe(res => {
              const book = this.allBooks.find((b) => lg.gradeId === b.gradeId);
              book.status = _.size(res) ? true : false;
              this.books.push(book);
              this.books = _.sortBy(this.books, b => b.sort);
              return book;
            },
            (err) => {
              toastErrorLoad(this.toast)
              this.load.hide()
            });
          });
        this.load.hide()
      }
    }

  }
}

