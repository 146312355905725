import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FullLoadingComponent } from './full-loading.component';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadings: Map<number, NgbModalRef> = new Map();

  constructor(
    private ngbModal: NgbModal
  ) {

  }

  show(backdrop?: boolean, key?: number) {
    setTimeout(() => {
      const k = key ? key : 1;
      if (this.loadings.has(k)) {
        this.hideIf(k)
      }
      const ref = this.ngbModal.open(
        FullLoadingComponent,
        {
          centered: true, keyboard: false,
          backdrop: backdrop ? backdrop : false,
          windowClass: 'modal-dialog-full',
          backdropClass: 'modal-backgroud',
        })
      this.loadings.set(k, ref)
    })
  }


  private hideIf(key?: number) {
    const k = key ? key : 1;
    const ngbModalRef = this.loadings.get(k);
    if (!isNullOrUndefined(ngbModalRef)) {
      this.closeTime(ngbModalRef);
      this.loadings.delete(k)
    }
  }

  hide(key?: number) {
    const k = key ? key : 1;
    const ngbModalRef = this.loadings.get(k);
    if (!isNullOrUndefined(ngbModalRef)) {
      this.closeTime(ngbModalRef);
      this.loadings.delete(k)
    } else {
      setTimeout(() => {
        this.hideIf(key)
      })
    }
  }

  closeTime(ref: NgbModalRef) {
    setTimeout(() => ref.close(), 50);
  }

}
