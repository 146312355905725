import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { toastErrorLoad } from '../../app/core/utils';
import { Router } from '@angular/router';
import { UserInfoService } from '../core/user-info.service';
@Component({
  selector: 'app-mark-paper',
  templateUrl: './mark-paper.component.html',
  styleUrls: ['./mark-paper.component.scss']
})
export class MarkPaperComponent implements OnInit {

  constructor(
    private toast: ToastrService,
    private route: Router,
    private userInfoService: UserInfoService
  ) { }
  loginData: LoginDTO = { loginName: '', password: '' }
  ngOnInit() {
  }
  clearLoginName() {
    this.loginData.loginName = '';
  }
  clearloginPwd() {
    this.loginData.password = '';
  }
  login() {
    if (this.loginData.loginName.trim() === '') {
      return   toastErrorLoad(this.toast, '请输入用户名');
    }
    if (this.loginData.password.trim() === '') {
      return   toastErrorLoad(this.toast, '请输入密码');
    }
    this.userInfoService.markPeoplelogin(this.loginData).subscribe(
      (res) => {
        if (res.success) {
          const value = {
            id: res.user.uid,
            name: res.user.name
          }
          localStorage.setItem('mark_loginInfo', JSON.stringify(value));
          this.route.navigate(['/mark', 'markingTest'])
        } else {
          toastErrorLoad(this.toast, res.message);
        }
      }, () => {
        toastErrorLoad(this.toast, '登录失败');
      }
    )
  }
}
