import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import {AddOrEditAccountComponent} from '../account-manage/add-or-edit-account/add-or-edit-account.component';
import { ManageService } from '../../core/manage.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent, IConfirmConfig } from '../../shared/confirm-dialog/confirm-dialog.component';
import {LoadingService} from '../../shared/full-loading/loading.service';
import {MarkConfirmDialogComponent} from '../../mark-paper/mark-confirm-dialog/mark-confirm-dialog.component';

@Component({
  selector: 'app-account-manage',
  templateUrl: './account-manage.component.html',
  styleUrls: ['./account-manage.component.scss']
})
export class AccountManageComponent implements OnInit {
  accounts = [];
  totalCount;
  count = 10;
  currentPage = 1;
  iConfig: IConfirmConfig = {};
  constructor(
    private ngbModal: NgbModal,
    private managerService: ManageService,
    private load: LoadingService,
  ) { }

  ngOnInit() {
    this.loadCount();
    this.loadAccounts();
  }

  loadCount() {
    this.managerService.getAccountCount().subscribe(res => {
      this.totalCount = res;
      if (res > 0) {
        this.loadAccounts();
      }
    })
  }

  loadAccounts() {
    this.load.show(true);
    this.managerService.getAccountLists(this.count * (this.currentPage - 1), this.count).subscribe(res => {
      this.load.hide();
      this.accounts = res;
    });
    this.load.hide();
  }

  addAccount() {
    const activeModal = this.ngbModal.open(
      AddOrEditAccountComponent,
      {
        keyboard: false,
        centered: true,
        backdrop: 'static',
        size: 'sm',
        windowClass: 'modal-dialog-account',
        backdropClass: 'modal-backgroud',
      }
    );
    activeModal.componentInstance.modalTitle = '添加用户';
    activeModal.result.then((result) => {
      if (result) {
        this.loadCount();
      }
    })
  }

  editAccount(a) {
    const activeModal = this.ngbModal.open(
      AddOrEditAccountComponent,
      {
        keyboard: false,
        centered: true,
        backdrop: 'static',
        size: 'sm',
        windowClass: 'modal-dialog-account',
        backdropClass: 'modal-backgroud',
      }
    );
    activeModal.componentInstance.modalTitle = '修改用户';
    activeModal.componentInstance.editAccountName = a.name;
    activeModal.componentInstance.editPassword = a.password;
    activeModal.componentInstance.editUid = a.uid;
    activeModal.result.then((result) => {
      if (result) {
        this.loadAccounts();
      }
    })
  }

  deleteAccount(a) {
    const confirmModal = this.ngbModal.open(MarkConfirmDialogComponent, {centered: true, size: 'sm'});
    confirmModal.componentInstance.config = {
      title: '删除账号',
      message: '确定删除此账号吗？',
      yes: () => {
        this.load.show();
        this.managerService.deleteAccount(a.uid).subscribe(() => {
          this.load.hide();
          confirmModal.close();
          this.loadCount();
        }, (err) => {
          this.load.hide();
          confirmModal.close();
        });
      }
    }
  }
}
